import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "hooks";
import { filterSubGraph } from "hooks/subGraph";
import { SUB_GRAPH_DATA } from "pages/Farm/types";
import { StockTokenWithPrice } from "pages/Mint/types";
import React from "react";
// import { makeStyles, createStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useDarkModeManager } from 'state/user/hooks'
import './index.css'

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     typography: {
//       padding: theme.spacing(2),
//     },
//   }),
// );

export function StockWalletPopover() {
//   const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [ isDark ] = useDarkModeManager()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const redirectTo = (href: string) => {
      return window.location.href = `${href}`
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="text-center">
      <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick} className={`${isDark ? `text-white` : `text-black`}`}>
        •••
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/* <Typography className={classes.typography}>The content of the Popover.</Typography> */}
        <div className={`popover-wrapper`}>
            <div className={`popover-item`} onClick={() => redirectTo(`#/mint`)}>Mint</div>
            <div className={`popover-item`} onClick={() => redirectTo(`#/redeem`)}>Redeem</div>
            <div className={`popover-item border-bottom-0`} onClick={() => redirectTo(`#/swap`)}>Swap</div>
        </div>
      </Popover>
    </div>
  );
}

export function TopGainerRow(subGraphData: SUB_GRAPH_DATA, stock: StockTokenWithPrice, index: number) {
    // const [toggleShow, setToggleShow] = useState(false)

    return subGraphData ? (
        <tr className={``} key={index + `-TopGainerRow`}>
            <td className="name">
                {`d` + stock.name}
                {parseFloat(filterSubGraph(stock.name, subGraphData).tokenPrice0.toString()) - parseFloat(stock.price.toString()) > 0 ?
                    <FontAwesomeIcon icon={faArrowUp} style={{ color: `#19c6bc`, marginLeft: `0.5rem`, fontSize: `0.85rem` }} />
                    :
                    <FontAwesomeIcon icon={faArrowDown} style={{ color: `#ff0800`, marginLeft: `0.5rem`, fontSize: `0.85rem` }} />
                }
            </td>
            <td className="name">
                <div className="dollar-sign d-inline mr-1">$</div>
                {`` + numberWithCommas(parseFloat(filterSubGraph(stock.name, subGraphData).tokenPrice0.toString()).toFixed(2))}
            </td>
            <td className="">
                <div className="dollar-sign d-inline mr-1">$</div>
                {numberWithCommas((stock.price).toFixed(2))}
            </td>
            {/* <td className={` ${(parseFloat(filterSubGraph(price.name, subGraphData).tokenPrice0.toString()) - parseFloat(price.price.toString())) > 0 ? `green` : `red`}`}>
            {(parseFloat(filterSubGraph(price.name, subGraphData).tokenPrice0.toString()) - parseFloat(price.price.toString())).toFixed(2)}
          </td> */}
            <td className={`d-none d-sm-block ${(parseFloat(filterSubGraph(stock.name, subGraphData).tokenPrice0.toString()) - parseFloat(stock.price.toString())) > 0 ? `green` : `red`}`}>
                {(100 * Math.abs(parseFloat(filterSubGraph(stock.name, subGraphData).tokenPrice0.toString()) - parseFloat(stock.price.toString())) / (stock.price as number)).toFixed(2) + `%`}
            </td>
            <td className="">
                <div className="header-2">
                    <select name="action" id="action" className="action-btn-wrapper" defaultValue="•••">
                        <option value="default" className="d-none">•••</option>
                        <option value="Mint">Mint</option>
                        <option value="Swap">Swap</option>
                    </select>
                </div>
            </td>
        </tr>
    ) : ``
}