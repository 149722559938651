import React, { useEffect } from 'react'
import { useState } from 'react'
import { scaleRotate as Menu } from 'react-burger-menu'
import { useDarkModeManager } from 'state/user/hooks'
import './index.css'
import MenuIcon from 'assets/images/menu-icon.png'
import MenuIconDark from 'assets/images/menu-icon-dark.png'
import styled from 'styled-components'
import { darken } from 'polished'
import Web3Status from 'components/Web3Status'
import { useActiveWeb3React } from 'hooks'
import TwinLogo from '../../assets/svg/logo-navbar.svg'
import TwinLogoDark from '../../assets/svg/logo-navbar-dark.svg'
// import TwinSmall from 'assets/svg/twin-token.svg'
import { TwinBalance, TwinPriceBurger } from 'components/Twindex'
import { BigNumber } from 'mathjs'
import { TWINDEX_TOKEN_ADDRESSES } from 'pages/Farm/constants'
import { useTokenContract } from 'hooks/useContract'
// import { faTimes } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Switch from 'react-switch'

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  :focus {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100%;
  `};
  color: ${({ theme }) => theme.primary1};
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    color: ${({ theme }) => 'white'};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`

export default function BurgerMenu() {
  const { account, chainId } = useActiveWeb3React()
  const [balance, setBalance] = useState<BigNumber | null>(null)
  const tokenContract = useTokenContract(TWINDEX_TOKEN_ADDRESSES[chainId ? chainId : 56])
  const [isDark] = useDarkModeManager()
  const [isDisplay, setIsDisplay] = useState(false)
  const [apiData, setApiData] = useState<any | null>(null)
  const [toggleToAnotherVersion, setToggleToAnotherVersion] = useState(false)
  const fetchData = () => {
    return fetch('https://api.twindex.com/')
      .then(response => response.json())
      .then(data => {
        if (!apiData) setApiData(data)
      })
  }
  useEffect(() => {
    fetchData()

    // Interval loop
    // const intervalId = setInterval(fetchData, 60000)
    // return () => clearInterval(intervalId)
  }, [])

  const update = async () => {
    if (account && tokenContract && !balance) {
      const result = await tokenContract.balanceOf(account)
      if (!balance && balance !== result) setBalance(result)
    }
  }

  useEffect(() => {
    if (!balance) update()
  }, [])

  useEffect(() => {
    if (toggleToAnotherVersion) window.location.href = `https://twindex.com/#/`
  }, [toggleToAnotherVersion])


  function toggleButtonClicked(input: boolean) {
    setIsDisplay(input)
  }

  // render() {
  // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
  return (
    <div
      id={`outer-container`}
      className={`${isDark ? `dark` : `light`} outer-container ${isDisplay ? `displayed` : ``}`}
    >
      <Menu
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
        onOpen={() => toggleButtonClicked(true)}
        onClose={() => toggleButtonClicked(false)}
        isOpen={isDisplay}
        className={isDisplay ? `` : `d-none`}
      >
        <div className="d-none"></div>
        <img
          src={isDark ? TwinLogoDark : TwinLogo}
          alt="logo"
          className={`twin-logo`}
          onClick={() => (window.location.href = '/')}
        />
        {/* <button type="button" className="close-btn text-center" onClick={() => toggleButtonClicked(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </button> */}
        <a id="swap" className="menu-item" href="https://exchange.twindex.com/#/swap">
          Swap
        </a>
        <a id="pool" className="menu-item" href="https://exchange.twindex.com/#/pool">
          Pool
        </a>
        <a id="farm" className="menu-item" href="#/farm">
          Farm
        </a>
        <a id="mint" className="menu-item" href="#/mint">
          Redeem
        </a>
        <a id="trade" className="menu-item" href="https://swap.arken.finance/#/market?filter=synthetic_assets" target={'_blank'} >
          Trade
        </a>
        {/* <a id="dashboard" className="menu-item" href="#/dashboard">
          Dashboard
        </a> */}
        <a id="info" className="menu-item" href="https://exchange.twindex.com/#/info">
          Info
        </a>
        <a id="convert" className="menu-item" href="#/convert">
          Convert
        </a>

        <AccountElement
          active={!!account}
          style={{ pointerEvents: 'auto' }}
          className={`container ${isDisplay ? `` : `d-none`}`}
        >
          <Switch
            className="switch-version mb-2"
            uncheckedIcon={<div className="w-100 h-100 text-center tag mr-2">V1</div>}
            checkedIcon={<div className="w-100 h-100 text-center tag pl-2">V2</div>}
            onChange={() => setToggleToAnotherVersion(!toggleToAnotherVersion)}
            checked={toggleToAnotherVersion}
            onColor={`#19c6bc`}
          />
          <div className="d-flex mb-2" style={{ gap: '0.4rem' }}>
            {apiData && apiData.twin_price ? (
              <div className={`w-100 pr-0 pl-0`}>{TwinPriceBurger(apiData.twin_price, isDark)}</div>
            ) : null}
            {apiData && apiData.twin_price ? (
              <div className="w-100 pr-0 pl-0">{TwinBalance(balance, apiData.twin_price)}</div>
            ) : null}
          </div>
          <Web3Status />
        </AccountElement>
      </Menu>
      <main id="page-wrap">
        <button type="button" className={`menu-button text-center ${isDisplay ? `d-none` : ``}`}>
          <img src={isDark ? MenuIconDark : MenuIcon} alt="menu-icon" className="menu-icon" />
        </button>
      </main>
    </div>
  )
  // }
}
