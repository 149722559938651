import { useContract } from 'components/SearchModal/MintFunction'
import TWINDEX_TOKEN_ABI from './TWIN_TOKEN_TESTNET_ABI.json'
import { useActiveWeb3React } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
// import { weiToFixed } from 'utils/math-helpers'
import { tokenList, TWX_Converter } from './convertToken'
import TWX_TOKEN_ABI from './TWX_TOKEN_ABI.json'
import Convert_ABI from './convert.json'
import { bignumber, BigNumber } from 'mathjs'

function useTwxContract(withSignerIfPossible?: any) {
    return useContract(tokenList[1].address, TWX_TOKEN_ABI, withSignerIfPossible)
}

function useTwinContract(withSignerIfPossible?: any) {
    return useContract(tokenList[0].address, TWINDEX_TOKEN_ABI, withSignerIfPossible)
}

function useConvertContract(withSignerIfPossible?: any){
    return useContract(TWX_Converter.ADDRESS, Convert_ABI, withSignerIfPossible)
}

const useTwindexBalanceOf = (): { balanceTwin: BigNumber, balanceTwx: BigNumber; update: () => void } => {
  const { account } = useActiveWeb3React()
  const [balanceTwin, setBalanceTwin] = useState<BigNumber>(bignumber(0))
  const [balanceTwx, setBalanceTwx] = useState<BigNumber>(bignumber(0))

  const tokenTwinContract = useContract(tokenList[0].address, TWX_TOKEN_ABI)
  const tokenTwxContract = useContract(tokenList[1].address, TWX_TOKEN_ABI)

  const update = useCallback(async () => {
    if (account && tokenTwxContract && tokenTwinContract) {
      const resultTwin: BigNumber = await tokenTwinContract.balanceOf(account)
      const resultTwx: BigNumber = await tokenTwxContract.balanceOf(account)
      const twinBN = bignumber(resultTwin.toString())
      const twxBN = bignumber(resultTwx.toString())
      setBalanceTwin(twinBN)
      setBalanceTwx(twxBN)
    }
  }, [account])

  useEffect(() => {
    update()
  }, [update])

  return { balanceTwin , balanceTwx, update }
}

export {
  useTwinContract,
  useTwxContract,
  useTwindexBalanceOf,
  useConvertContract
}
