const APP_CONFIG = {
  MAINTAINANCE: 0,
  MINT: 1,
  SWAP: 1,
  POOL: 1,
  FARM: 1,
  TRADE: 1,
  INFO: 1
}

export default APP_CONFIG
