import React, { useEffect, useState } from 'react'
import './farm.css'
import { useDarkModeManager } from 'state/user/hooks'
import {
  useStakingAllowance,
  useStakingBalance,
  useStakingDepositBalance,
  useStakingReward,
  useTwindexBalanceOf,
  useGetTwindexLockedAmount,
  useTwindexUnlockReward
} from './hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { weiToFixed } from 'utils/math-helpers'
// import { useContract } from 'components/SearchModal/MintFunction'
// import { useActiveWeb3React } from 'hooks'
import LogoEarnedIcon from '../../assets/images/logo-earned.svg'
import { StakingItem } from './StakingItems'
import { useContract } from 'components/SearchModal/MintFunction'
import { STAKING_LIST, TWINDEX_TOKEN_ADDRESSES } from './constants'
import TWINDEX_TOKEN_ABI from 'constants/abis/twindex-token-abi.json'
import { useActiveWeb3React } from 'hooks'
import Modal from 'components/Modal'

function numberWithCommas(x: string) {
  const y = Number.parseFloat(x).toFixed(2)
  return y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default function FarmPage() {
  const [isDark] = useDarkModeManager()
  const { chainId } = useActiveWeb3React()

  const { allowanceResult, update: updateAllowance } = useStakingAllowance()
  const { balanceResult, update: updateBalance } = useStakingBalance()
  const { depositBalanceResult, update: updateDepositBalance } = useStakingDepositBalance()
  const { stakingRewardResult, totalUnclaimReward, update: updateStakingReward } = useStakingReward()
  const [apiData, setApiData] = useState<any | null>(null)
  const { balance: twindexBalance, update: updateTwindexBalanceOf } = useTwindexBalanceOf()
  const { lockedAmount: twindexLockedAmount, update: updateTwindexLockedAmount } = useGetTwindexLockedAmount()
  const { update: loadUnlockReward, claim: claimUnlockReward, claimResult, canUnlockAmount } = useTwindexUnlockReward()
  const tokenContract = useContract(TWINDEX_TOKEN_ADDRESSES[chainId ? chainId : 56], TWINDEX_TOKEN_ABI)

  const updateAllData = () => {
    updateAllowance()
    updateBalance()
    updateDepositBalance()
    updateStakingReward()
    updateTwindexBalanceOf()
    updateTwindexLockedAmount()
  }

  const fetchData = () => {
    return fetch('https://api.twindex.com/')
      .then(response => response.json())
      .then(data => {
        // console.log('api_data', data)
        if (!apiData) setApiData(data)
      })
  }
  useEffect(() => {
    // const subGraph = () => {
    //   return (async () => {
    //     const rawResponse = await fetch('https://api.thegraph.com/subgraphs/name/ekaomk/twindex-subgraph', {
    //       method: 'POST',
    //       // headers: {
    //       //   'Accept': 'application/json',
    //       //   'Content-Type': 'application/json'
    //       // },
    //       body: JSON.stringify({"query":"{\n  TWIN_DOLLY:pair(id: \"0x593747cd0023669c7a67511406266559b166ca5d\"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n  TWIN_BNB:pair(id: \"0xc3bfdd2cd502e719132bf7aa5954c74e9c7209f5\"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n DOP_TWIN: pair(id: \"0x65a95c2bc5c12e8e30e24d322ff386249c29a072\"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n}","variables":null})
    //     });
    //     const content = await rawResponse.json();

    //     console.log(content);
    //   })();
    // }
    fetchData()
    // subGraph()
    // Interval loop
    // const intervalId = setInterval(() => {
    //   fetchData()
    //   setTimeout(() => {
    //     updateAllData()
    //   }, 10000)
    // }, 60000)
    // return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <div className={`${isDark ? 'dark' : 'light'} container farm px-0`}>
        <RewardCard
          totalUnclaimReward={totalUnclaimReward}
          twinPrice={apiData ? apiData.twin_price : 0}
          balance={twindexBalance}
          lockedAmount={twindexLockedAmount}
          apiData={apiData}
          claimUnlockReward={claimUnlockReward}
          loadUnlockReward={loadUnlockReward}
          claimResult={claimResult}
          canUnlockAmount={canUnlockAmount}
          twinTokenContract={tokenContract}
          updateBalance={updateBalance}
        />
        <div className="staking-container w-100">
          <h4 className="mb-4">Farming Twindex</h4>
          <div className="staking-title row mb-4 text-center">
            <div className="col-5">LP Tokens Name</div>
            <div className="col-2">APR</div>
            <div className="col-3">TVL</div>
            <div className="col-2">Earned</div>
          </div>
          <div className="staking-list">
            {STAKING_LIST.map((sl, i) =>
              i >= 4 ? (
                ''
              ) : (
                <div key={sl.name}>
                  <StakingItem
                    stakingDataItem={sl}
                    allowance={allowanceResult.length ? allowanceResult[i].allowance : BigNumber.from(0)}
                    balance={balanceResult.length ? balanceResult[i].balance : BigNumber.from(0)}
                    depositBalance={depositBalanceResult.length ? depositBalanceResult[i].balance : BigNumber.from(0)}
                    stakingReward={stakingRewardResult.length ? stakingRewardResult[i].reward : BigNumber.from(0)}
                    updateAllData={updateAllData}
                    apiData={apiData && apiData.lp_data[sl.lp_address]}
                    twinPrice={apiData && apiData.twin_price}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <div className="staking-container w-100">
          <h4 className="mb-4">Synthetic </h4>
          <div className="staking-title row mb-4 text-center">
            <div className="col-5">LP Tokens Name</div>
            <div className="col-2">APR</div>
            <div className="col-3">TVL</div>
            <div className="col-2">Earned</div>
          </div>
          <div className="staking-list">
            {STAKING_LIST.map((sl, i) =>
              i <= 3 || i > 12 ? (
                ''
              ) : (
                <div key={sl.name}>
                  <StakingItem
                    stakingDataItem={sl}
                    allowance={allowanceResult.length ? allowanceResult[i].allowance : BigNumber.from(0)}
                    balance={balanceResult.length ? balanceResult[i].balance : BigNumber.from(0)}
                    depositBalance={depositBalanceResult.length ? depositBalanceResult[i].balance : BigNumber.from(0)}
                    stakingReward={stakingRewardResult.length ? stakingRewardResult[i].reward : BigNumber.from(0)}
                    updateAllData={updateAllData}
                    apiData={apiData && apiData.lp_data[sl.lp_address]}
                    twinPrice={apiData && apiData.twin_price}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <div className="staking-container w-100">
          <h4 className="mb-4">Legacy Twindex</h4>
          <div className="staking-title row mb-4 text-center">
            <div className="col-5">LP Tokens Name</div>
            <div className="col-2">APR</div>
            <div className="col-3">TVL</div>
            <div className="col-2">Earned</div>
          </div>
          <div className="staking-list">
            {STAKING_LIST.map((sl, i) =>
              i < 13 ? (
                ''
              ) : (
                <div key={sl.name}>
                  <StakingItem
                    stakingDataItem={sl}
                    allowance={allowanceResult.length ? allowanceResult[i].allowance : BigNumber.from(0)}
                    balance={balanceResult.length ? balanceResult[i].balance : BigNumber.from(0)}
                    depositBalance={depositBalanceResult.length ? depositBalanceResult[i].balance : BigNumber.from(0)}
                    stakingReward={stakingRewardResult.length ? stakingRewardResult[i].reward : BigNumber.from(0)}
                    updateAllData={updateAllData}
                    apiData={apiData && apiData.lp_data[sl.lp_address]}
                    twinPrice={apiData && apiData.twin_price}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  )
}

interface RewardCardProps {
  totalUnclaimReward: number
  claimResult: boolean
  canUnlockAmount: BigNumber
  twinPrice: number
  balance: BigNumber
  lockedAmount: BigNumber
  apiData: any
  twinTokenContract: any
  claimUnlockReward: () => void
  loadUnlockReward: () => void
  updateBalance: () => void
}

const RewardCard = (props: RewardCardProps) => {
  const [loading, setLoading] = useState(false)
  const [isDark] = useDarkModeManager()

  const handleUnlockReward = async () => {
    setLoading(true)
    props.twinTokenContract
      .unlock()
      .then(async (result: any) => {
        await result.wait()
        props.loadUnlockReward()
        props.updateBalance()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onModalDismiss = () => {
    console.log('')
  }


  useEffect(() => {
    const intervalId = setInterval(() => {
      props.loadUnlockReward()
    }, 10000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <div>
        <Modal isOpen={loading} onDismiss={onModalDismiss} maxHeight={90}>
          <div className={`modal-loading ${isDark ? 'text-white' : 'text-black'}`}>
            <div className="text-center">
              <div className="spinner-border text-primary mx-auto mb-4" role="status"></div>
              <div className="ml-3">Waiting for Transaction...</div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="container farm mt-4">
        <div className="row">
          <div className="reward-panel reward-earn col-12 col-sm-6">
            <div className="text">
              <div className="title">Twindex earned</div>
              <div className="body">{numberWithCommas(props.totalUnclaimReward.toString())}</div>
              <div className="sub">
                {props.twinPrice || props.twinPrice === 0
                  ? '≈ $' + numberWithCommas((props.totalUnclaimReward * props.twinPrice).toString())
                  : 'loading...'}
              </div>
              <div className="title d-none">{props.apiData ? props.apiData.sum_tvl : ``}</div>
            </div>
            <div className="logo">
              <img src={LogoEarnedIcon} alt="" />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="row pl-sm-4 mt-4 mt-sm-0">
              <div className="reward-panel twindex-balance col-12 mb-4">
                <div className="header">Your Twindex wallet balance</div>
                <div className="body">
                  <div className="title">{numberWithCommas(weiToFixed(props.balance?.toString(), 2))}</div>
                  <div className="sub">
                    {props.twinPrice || props.twinPrice === 0
                      ? '≈ $' +
                      numberWithCommas(
                        (parseFloat(weiToFixed(props.balance?.toString(), 2)) * props.twinPrice).toString()
                      )
                      : 'loading...'}
                  </div>
                </div>
              </div>
              <div className="reward-panel locked-amount col-12">
                <div className="title d-flex">
                  <span>Locked amount :&nbsp;</span>
                  <span className="text-green">{numberWithCommas(weiToFixed(props.lockedAmount.toString(), 2))}</span>
                  <button className="btn-unlock-reward" onClick={handleUnlockReward}>
                    Unlock : {numberWithCommas(parseFloat(weiToFixed(props.canUnlockAmount.toString(), 2)).toString())}{' '}
                    TWIN
                  </button>
                </div>
                <div className="sub mt-1">80% of your rewards are locked and available after 30 days.</div>
                <div className="sub mt-1">100% rewards unlock in 30 days or 3.33% / day.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
