import React from 'react'
import twinTokenIcon from 'assets/images/staking/twin.svg'
import twinTokenDarkIcon from 'assets/images/staking/dark-twin.svg'
import { InputProp } from '../types'
import { useDarkModeManager } from 'state/user/hooks'

export const ConvertInput = (props: InputProp) => {
  const [isDark] = useDarkModeManager()
  return (
    <div className="input-wrapper d-flex">
      <div className="left w-100">
        <div className="row-1 d-flex">
          <div className="header-1 w-100">Input</div>
          <div className="balance w-100">Balance: {props.balance}</div>
        </div>
        <div className="row-2 d-flex">
          <div className="input-box w-100 d-flex">
            <img src={isDark ? twinTokenDarkIcon : twinTokenIcon} alt="twinTokenIcon" className="twin-token-icon" />
            <span className="header">TWIN</span>
          </div>
          <input
            type="number"
            onFocus={e => {
              if (e.target.value === `0`) e.target.value = ``
            }}
            value={props.input}
            onChange={e => props.setInput(e.target.value)}
            className="input-balance w-100 text-right"
          />
        </div>
      </div>
      <div className="right text-center">
        <div className="ratio">Ratio</div>
        <div className="value text-truncate">1 TWIN</div>
      </div>
    </div>
  )
}
