import { bignumber } from 'mathjs'
import { QUERY_ALL } from 'pages/Farm/constants'
import { STOCK_LP, SUB_GRAPH, TOKEN_LP } from 'pages/Farm/types'
import { stockTokens } from 'pages/Mint/stockTokens'
import { StockTokenWithPrice } from 'pages/Mint/types'
import { weiToFixed } from 'utils/math-helpers'
import mkrMulticall from 'utils/mkrMulticall'
import PRICE_FEED_ABI from '../constants/abis/SyntheticStock/priceFeed.json'

function parseContent(content: STOCK_LP | TOKEN_LP) {
  return content.token0
    ? {
      reserve0: content.token0,
      reserve1: content.token1,
      token0: content.token0,
      token1: content.token1,
      tokenPrice0: `${parseFloat(content.token1.derivedUSD) / parseFloat(content.token0.derivedUSD)}`,
      tokenPrice1: `${parseFloat(content.token0.derivedUSD) / parseFloat(content.token1.derivedUSD)}`,
      lpPrice: `${(parseFloat(content.token1.derivedUSD) / parseFloat(content.token0.derivedUSD)) * 2}`
    }
    : {
      reserve0: content.reserve0,
      reserve1: content.reserve1,
      token0: content.reserve0,
      token1: content.reserve1,
      tokenPrice0: `${parseFloat(content.reserve1) / parseFloat(content.reserve0)}`,
      tokenPrice1: `${parseFloat(content.reserve0) / parseFloat(content.reserve1)}`,
      lpPrice: `${(parseFloat(content.reserve1) / parseFloat(content.reserve0)) * 2}`
    }
}

export const getSubGraph = () => {
  return (async () => {
    const rawResponse = await fetch('https://api.thegraph.com/subgraphs/name/ekaomk/twindex-subgraph', {
      method: 'POST',
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json'
      // },
      body: JSON.stringify(QUERY_ALL)
    })
    const content = (await rawResponse.json()) as SUB_GRAPH
    const result = {}
    for (const [key, value] of Object.entries(content.data)) {
      // if (key && value) result.push({ [key]: parseContent(value)});
      if (key && value) Object.assign(result, { [key]: parseContent(value) })
    }
    return result
  })()
}

const calls = stockTokens.map(st => {
  return {
    address: '0xd4f061a6a276f8B0Ae83D210D838B45fCC7532B2',
    name: 'queryRate',
    params: [st.address, '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5']
  }
})

export const getTokenValueInDolly = () => {
  mkrMulticall(56, PRICE_FEED_ABI, calls).then(result => {
    const results: StockTokenWithPrice[] = []
    stockTokens.map((stock, index: number) => {
      results.push({
        ...stock,
        price: parseFloat(weiToFixed(bignumber(result[index].rate.toString()), 6))
      })
      return stock
    })
    return results
  })
}

export const getApiData = () => {
  return (async () => {
    const rawResponse = await fetch("https://api-v2.twindex.com/")
      .then((response) => response.json())
      .then((data) => {
        // console.log('api_data', data)
        return data
      })
    const content = await rawResponse.json()
    return content
  })()
}

export function filterSubGraph(target: string, data: any): TOKEN_LP {
  const res = Object.keys(data).find(key => key.split('_d')[1] === target)
  return data[`${res}`]
}
