import BnbLogo from "assets/svg/bnb-token.svg";
import DopLogo from "assets/svg/dop-token.svg";
import TwinLogo from "assets/svg/twin-token.svg";

import DopLogoDark from "assets/svg/dop-dark-token.svg";
import TwinLogoDark from "assets/svg/twin-dark-token.svg";

import BiduLogo from "assets/svg/bidu-token.svg";
import CoinLogo from "assets/svg/coin-token.svg";
import GooglLogo from "assets/svg/googl-token.svg";
import TslaLogo from "assets/svg/tsla-token.svg";
import XauLogo from "assets/svg/xau-token.svg";

import BiduLogoDark from "assets/svg/bidu-dark-token.svg";
import CoinLogoDark from "assets/svg/coin-dark-token.svg";
import GooglLogoDark from "assets/svg/googl-dark-token.svg";
import TslaLogoDark from "assets/svg/tsla-dark-token.svg";
import XauLogoDark from "assets/svg/xau-dark-token.svg";


import AaplLogo from "assets/svg/aapl-token.svg";
import AmznLogo from "assets/svg/amza-token.svg";
import SpceLogo from "assets/svg/spce-token.svg";
import SpyLogo from "assets/svg/spy-token.svg";
import AaplLogoDark from "assets/svg/aapl-dark-token.svg";
import AmznLogoDark from "assets/svg/amza-dark-token.svg";
import SpceLogoDark from "assets/svg/spce-dark-token.svg";
import SpyLogoDark from "assets/svg/spy-dark-token.svg";

import LandingAssetLogo01 from "assets/svg/trade-icon.svg";
import LandingAssetLogo02 from "assets/svg/24-icon.svg";
import LandingAssetLogo03 from "assets/svg/no-kyc-icon.svg";

export const TOKEN_CONTRACTS_HEADER = `Listed Token Addresses`;
export const TOKEN_CONTRACTS_HEADER2 = `You can get the addresses of listed tokens to learn more about each token, or add to your favorite wallets.`;
export const TOKEN_CONTRACTS = [
  {
    name: `TWX`,
    symbol: `TWX`,
    address: `0x41171D5770C4c68686d1aF042Ada88a45B02f82b`,
    image_url: `https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x41171D5770C4c68686d1aF042Ada88a45B02f82b.png`,
    image_url_dark: `https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x41171D5770C4c68686d1aF042Ada88a45B02f82b.png`,
  },
  {
    name: `DOPX`,
    symbol: `DOPX`,
    address: `0x802A183ac9F6b082716DFeE55432ed0C04ACB49a`,
    image_url: `https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x802A183ac9F6b082716DFeE55432ed0C04ACB49a.png`,
    image_url_dark: `https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x802A183ac9F6b082716DFeE55432ed0C04ACB49a.png`,
  },
  {
    name: `KUSD`,
    symbol: `KUSD`,
    address: `0x940Ff63e82d15fb47371BFE5a4ed7D7D183dE1A5`,
    image_url: `https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x940Ff63e82d15fb47371BFE5a4ed7D7D183dE1A5.png`,
    image_url_dark: `https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x940Ff63e82d15fb47371BFE5a4ed7D7D183dE1A5.png`,
  },
  {
    name: `Dolly`,
    symbol: `DOLLY`,
    address: `0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5`,
    image_url: `https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fdolly.svg&w=64&q=75`,
    image_url_dark: `https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fdolly.svg&w=64&q=75`,
  },
  {
    name: `Dopple`,
    symbol: `DOP`,
    address: `0x844fa82f1e54824655470970f7004dd90546bb28`,
    image_url: DopLogo,
    image_url_dark: DopLogoDark,
  },
  {
    name: `Twindex`,
    symbol: `TWIN`,
    address: `0x3806aae953a3a873D02595f76C7698a57d4C7A57`,
    image_url: TwinLogo,
    image_url_dark: TwinLogoDark,
  },
  {
    name: `BNB`,
    symbol: `BNB`,
    address: `0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c`,
    oracle_address: `0xc06fcEE64b08E666Bab5fda9c503B36826F8948e`,
    image_url: BnbLogo,
    image_url_dark: BnbLogo,
  }
];

export const ORACLE_CONTRACTS_HEADER = `dAsset Tokens`;
export const ORACLE_CONTRACTS_HEADER2 = `Here are the list of all dAssets. Token addresses are the addresses of token itself, 
while Oracle addresses provides the price reference for minting and redemption of each token.`;
export const ORACLE_CONTRACTS = [
  {
    name: `AAPL`,
    address: `0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3`,
    oracle_address: `0xa715B865a981Db7E6d500030b6B5F572df184D59`,
    image_url: AaplLogo,
    image_url_dark: AaplLogoDark,
  },
  {
    name: `GOOGL`,
    address: `0x9C169647471C1C6a72773CfFc50F6Ba285684803`,
    oracle_address: `0x389a094aa1b549df4515F5f4B691C0c32706fB5b`,
    image_url: GooglLogo,
    image_url_dark: GooglLogoDark,
  },
  {
    name: `AMZN`,
    address: `0x1085B90544ff5C421D528aAF79Cc65aFc920aC79`,
    oracle_address: `0xC032d80C513Ca88adD0de1f452995FcC831e173e`,
    image_url: AmznLogo,
    image_url_dark: AmznLogoDark,
  },
  {
    name: `TSLA`,
    address: `0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4`,
    oracle_address: `0x29ED6B59E46dD0751259198E552541edDE7c3F25`,
    image_url: TslaLogo,
    image_url_dark: TslaLogoDark,
  },
  {
    name: `COIN`,
    address: `0xB23DC438b40cDb8a625Dc4f249734811F7DA9f9E`,
    oracle_address: `0xEbafb379ffE60e9f2Dd9f24d9fe307dcE3092c3A`,
    image_url: CoinLogo,
    image_url_dark: CoinLogoDark,
  },
  {
    name: `BAIDU`,
    address: `0x48D2854529183e1de3D36e29D437f8F6043AcE17`,
    oracle_address: `0x39b76a9b5cc283Dd9e6766a4121f3C779F8867F3`,
    image_url: BiduLogo,
    image_url_dark: BiduLogoDark,
  },
  {
    name: `SPCE`,
    address: `0x75bD0500548B49455D2Dfd86fa30Fba476Cb3895`,
    oracle_address: `0x6e41b4acCC838D2228075c688f1B2881c38ba443`,
    image_url: SpceLogo,
    image_url_dark: SpceLogoDark,
  },
  {
    name: `SPY`,
    address: `0xf2018b59F8f9BE020C12Cb0A2624200d9FBa2af1`,
    oracle_address: `0x4DC3e4f4016c58bAa2df82050886E54B8d258d41`,
    image_url: SpyLogo,
    image_url_dark: SpyLogoDark,
  },
  {
    name: `XAU`,
    address: `0x76a9ebf68d4a383B57a41a528dF51CC89bF8c931`,
    oracle_address: `0x0da4Cf76660EAa9160372f4a45B1ec9A264Aae19`,
    image_url: XauLogo,
    image_url_dark: XauLogoDark,
  },
];

export const OTHER_CONTRACTS_HEADER = `Other Addresses`;
export const OTHER_CONTRACTS_HEADER2 = `Supplement smart contract to make Twindex perform its own mechanism correctly and trustworthily.`;
export const OTHER_CONTRACTS = [
  {
    name: `DFI Protocol`,
    address: `0x37f5a7D8bBB1cc0307985D00DE520fE30630790c`,
  },
  {
    name: `Price Feeds`,
    address: `0xd4f061a6a276f8B0Ae83D210D838B45fCC7532B2`,
  },
  {
    name: `Loan Logic Standard`,
    address: `0x6e0C17f56d0d97f83d400A66C508307Bce6f8E38`,
  },
];


export const LANDING_SYNTHETIC_ASSETS = [
  {
    icon: LandingAssetLogo01,
    header: `Better Dividend`,
    body: `Earn more than 20% profit compared to 
    traditional trading dividends via easy-to-follow 
    yield farming, and you can still benefit from 
    capital gains.`
  },
  {
    icon: LandingAssetLogo02,
    header: `Trade with Various 
    Foreign Assets`,
    body: `Twindex opens the opportunities to trade variety 
    of stock tokens of any country - USA, China, 
    Vietnam from anywhere else in the world without 
    restrictions`
  },
  {
    icon: LandingAssetLogo03,
    header: `No KYC, 24/7 Trading`,
    body: `Based on Blockchain and DeFi concept, there 
    are no user identification needed. Start trading 
    right away without market closes.`
  }
]