export const TWIN = {
  ADDRESS: `0x3806aae953a3a873D02595f76C7698a57d4C7A57`,
  DL_ADDRESS: `0x3806aae953a3a873D02595f76C7698a57d4C7A57`
}
export const TWX = {
  ADDRESS: `0x41171D5770C4c68686d1aF042Ada88a45B02f82b`,
  DL_ADDRESS: `0x41171D5770C4c68686d1aF042Ada88a45B02f82b`
}

export const TWX_Converter = {
  ADDRESS: `0x6320F0C37f271f442D0BA4AA6478741618a67cE9`
}

export const tokenList =
[
  {
      chainId: 56,
      address: `${TWIN.DL_ADDRESS}`,
      name: 'Twindex',
      symbol: 'TWIN',
      decimals: 18,
      logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0xA977b72BB3063D2e013C28aC048b87160f55efFB.png'
 }
,{
      chainId: 56,
      address: `${TWX.DL_ADDRESS}`,
      name: 'Twin X',
      symbol: 'TWX',
      decimals: 18,
      logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0xA977b72BB3063D2e013C28aC048b87160f55efFB.png'
}
]