import React, { useEffect, useState } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useContract } from 'components/SearchModal/MintFunction'
import TWINDEX_LP_ABI from 'constants/abis/staking/twindex_lp.json'
import FAIRLAUNCH_ABI from 'constants/abis/staking/fairlaunch.json'
import { MaxUint256 } from '@ethersproject/constants'
import { useWeiAmount } from 'hooks/useWeiAmount'
// import { ChainId } from '@uniswap/sdk'
import Modal from 'components/Modal'
import { BigNumber } from 'ethers'
import { useDarkModeManager } from 'state/user/hooks'
import { weiToFixed } from 'utils/math-helpers'
import { StakingItemProps } from './types'

function numberWithCommas(x: string) {
    const y = Number.parseFloat(x).toFixed(2)
    return y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function numberWithCommasFixedZero(x: string) {
    const y = Number.parseFloat(x).toFixed(0)
    return y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}



export const LegacyStakingItem = (props: StakingItemProps) => {
    const [toggleShow, setToggleShow] = useState(false)
    // const [weight, setWeight] = useState('0')
    const [depositInput, setDepositInput] = useState('')
    const [withdrawInput, setWithdrawInput] = useState('')
    const depositWei = useWeiAmount(depositInput)
    const withdrawWei = useWeiAmount(withdrawInput)
    const [isDark] = useDarkModeManager()

    const [loading, setLoading] = useState(false)
    // console.log('props.apiData', props.apiData)

    const { account } = useActiveWeb3React()
    const fairlaunchAddress = '0xe6bE78800f25fFaE4D1db7CA6d3485629bD200Ed'
    const lpContract = useContract(props.stakingDataItem.lp_address, TWINDEX_LP_ABI)
    const fairlaunchContract = useContract(fairlaunchAddress, FAIRLAUNCH_ABI)

    const approve = async () => {
        const transaction = await lpContract?.approve(fairlaunchAddress, MaxUint256)
        await transaction.wait()
        props.updateAllData()
    }
    const deposit = async () => {
        const transaction = await fairlaunchContract?.deposit(account, props.stakingDataItem.pool_id, depositWei)
        await transaction.wait()
        props.updateAllData()
    }
    const withdraw = async () => {
        const transaction = await fairlaunchContract?.withdraw(account, props.stakingDataItem.pool_id, withdrawWei)
        await transaction.wait()
        props.updateAllData()
    }
    const claimReward = async () => {
        const transaction = await fairlaunchContract?.harvest(props.stakingDataItem.pool_id)
        await transaction.wait()
        props.updateAllData()
    }
    const disableDeposit = !(
        depositInput &&
        BigNumber.from(depositWei).gt(0) &&
        props.balance.gte(BigNumber.from(depositWei)) &&
        !props.allowance.isZero()
    )
    const disableWithdraw = !(
        withdrawInput &&
        BigNumber.from(withdrawWei).gt(0) &&
        props.depositBalance.gte(BigNumber.from(withdrawWei))
    )
    const disableClaim = props.stakingReward.isZero()

    // console.log('props.allowance', props.allowance)

    async function loadDataFromAPI() {
        // setWeight('0')
    }

    useEffect(() => {
        loadDataFromAPI()
    }, [])

    const handleApproveBtn = async () => {
        setLoading(true)
        await approve().finally(() => {
            setLoading(false)
        })
    }

    const handleDepositBtn = async () => {
        setLoading(true)
        await deposit().finally(() => {
            setLoading(false)
        })
        setDepositInput('')
    }

    const handleWithdrawBtn = async () => {
        setLoading(true)
        await withdraw().finally(() => {
            setLoading(false)
        })
        setWithdrawInput('')
    }

    const handleClaimBtn = async () => {
        setLoading(true)
        await claimReward().finally(() => {
            setLoading(false)
        })
    }

    const handleMaxDepositBtn = () => {
        setDepositInput(weiToFixed(props.balance.toString(), 18))
    }

    const handleMaxWithdrawBtn = () => {
        setWithdrawInput(weiToFixed(props.depositBalance.toString(), 18))
    }

    const wrappedOnDismiss = () => {
        console.log('')
    }

    return (
        <>
            <div className={`staking-item ${toggleShow ? 'active' : ''} mb-4`}>
                <Modal isOpen={loading} onDismiss={wrappedOnDismiss} maxHeight={90}>
                    <div className={`modal-loading ${isDark ? 'text-white' : 'text-black'}`}>
                        <div className="text-center">
                            <div className="spinner-border text-primary mx-auto mb-4" role="status"></div>
                            <div className="ml-3">Waiting for Transaction...</div>
                        </div>
                    </div>
                </Modal>
                <div className="staking-header row" onClick={() => setToggleShow(!toggleShow)}>
                    <div className="tokens-group col-5 d-flex">
                        <div className="d-flex align-items-center">
                            <div className="img-logo">
                                <img src={props.stakingDataItem.icon} alt="" />
                            </div>
                            <div className={`title-group`}>
                                <div className="text-title">{props.stakingDataItem.name} LPs</div>
                                <a target="_blank" rel="noreferrer" href={props.stakingDataItem.add_lp_url}>
                                    {props.stakingDataItem.lp_address === `0x3806aae953a3a873D02595f76C7698a57d4C7A57` ?
                                        < div className="text-sub">Buy {props.stakingDataItem.name}</div>
                                        : <div className="text-sub">Remove {props.stakingDataItem.name} LP</div>
                                    }
                                </a>
                            </div>
                        </div>
                        <div className="weight-badge">{props.apiData && props.apiData.alloc_point}X</div>
                    </div>
                    <div className="apr-group col-2">
                        <div>
                            <div className="title">
                                APR : {props.apiData && numberWithCommasFixedZero(props.apiData.apr.toString())}%
                            </div>
                            <div className="sub">
                                ≈ {props.apiData && numberWithCommas((props.apiData.apr / 365).toFixed(2))}% daily
                            </div>
                        </div>
                    </div>
                    <div className="tvl-group col-3">
                        ${props.apiData && numberWithCommasFixedZero(props.apiData.tvl.toString())}
                    </div>
                    <div className="earned-group col-2">
                        {props.stakingReward.isZero() ? '0.00' : numberWithCommas(weiToFixed(props.stakingReward.toString(), 2))}
                    </div>
                </div>
                <hr className="staking-divider" />
                <div className="staking-body d-block">
                    <div className="row">
                        <div className="col-12 col-sm-9 d-block">
                            <div className="row">
                                <div className="col-12 px-4 px-sm-3 col-sm-6 mt-2 mt-sm-0 ">
                                    <div className="w-100">
                                        Balance:{' '}
                                        <span className="text-balance">
                                            {props.balance.isZero() ? '0' : weiToFixed(props.balance.toString(), 2)}
                                        </span>
                                    </div>
                                    <div className="w-100">
                                        <input
                                            type="number"
                                            placeholder="0.00"
                                            className="staking-input"
                                            value={depositInput}
                                            onChange={e => setDepositInput(e.target.value)}
                                        />
                                        <button onClick={handleMaxDepositBtn} className="btn-max">
                                            Max
                                        </button>
                                    </div>
                                    <div className="d-flex gap-1">
                                        <button
                                            onClick={handleApproveBtn}
                                            className={`btn-staking-confirm mb-sm-0 mb-4 ${!props.allowance.isZero() ? 'd-none' : ''}`}
                                        >
                                            Approve
                                        </button>
                                        <button
                                            onClick={handleDepositBtn}
                                            className={`btn-staking-confirm mb-sm-0 mb-4 ${disableDeposit ? 'disabled' : ''}`}
                                            disabled={disableDeposit}
                                        >
                                            Deposit
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 px-4 px-sm-3 col-sm-6">
                                    <div className="w-100">
                                        Deposited:{' '}
                                        <span className="text-balance">
                                            {props.depositBalance.isZero() ? '0' : weiToFixed(props.depositBalance.toString(), 2)}
                                        </span>
                                    </div>
                                    <div className="w-100">
                                        <input
                                            type="number"
                                            placeholder="0.00"
                                            className="staking-input"
                                            value={withdrawInput}
                                            onChange={e => setWithdrawInput(e.target.value)}
                                        />
                                        <button onClick={handleMaxWithdrawBtn} className="btn-max ">
                                            Max
                                        </button>
                                    </div>
                                    <div className="w-100">
                                        <button
                                            onClick={handleWithdrawBtn}
                                            disabled={disableWithdraw}
                                            className={`btn-staking-confirm btn-block ${disableWithdraw ? 'disabled' : ''}`}
                                        >
                                            Withdraw
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-4 px-sm-3 col-sm-3">
                            <div className="staking-reward">
                                <div className="title">
                                    {props.stakingReward.isZero()
                                        ? '0.00'
                                        : numberWithCommas(weiToFixed(props.stakingReward.toString(), 2))}
                                </div>
                                <div className="sub">
                                    (≈ $
                                    {props.stakingReward.isZero()
                                        ? '0.00'
                                        : numberWithCommas(
                                            (
                                                parseFloat(weiToFixed(props?.stakingReward?.toString(), 2)) *
                                                parseFloat(props?.twinPrice?.toString())
                                            ).toFixed(2)
                                        )}
                                    )
                                </div>
                                <button
                                    onClick={handleClaimBtn}
                                    disabled={disableClaim}
                                    className={`btn-claim mb-4 mb-sm-0 ${disableClaim ? 'disabled' : ''}`}
                                >
                                    Claim
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export const StakingItem = (props: StakingItemProps) => {
    const [toggleShow, setToggleShow] = useState(false)
    // const [weight, setWeight] = useState('0')
    const [depositInput, setDepositInput] = useState('')
    const [withdrawInput, setWithdrawInput] = useState('')
    const depositWei = useWeiAmount(depositInput)
    const withdrawWei = useWeiAmount(withdrawInput)
    const [isDark] = useDarkModeManager()

    const [loading, setLoading] = useState(false)
    // console.log('props.apiData', props.apiData)

    const { account } = useActiveWeb3React()
    const fairlaunchAddress = '0xe6bE78800f25fFaE4D1db7CA6d3485629bD200Ed'
    const lpContract = useContract(props.stakingDataItem.lp_address, TWINDEX_LP_ABI)
    const fairlaunchContract = useContract(fairlaunchAddress, FAIRLAUNCH_ABI)

    const approve = async () => {
        const transaction = await lpContract?.approve(fairlaunchAddress, MaxUint256)
        await transaction.wait()
        props.updateAllData()
    }
    const deposit = async () => {
        const transaction = await fairlaunchContract?.deposit(account, props.stakingDataItem.pool_id, depositWei)
        await transaction.wait()
        props.updateAllData()
    }
    const withdraw = async () => {
        const transaction = await fairlaunchContract?.withdraw(account, props.stakingDataItem.pool_id, withdrawWei)
        await transaction.wait()
        props.updateAllData()
    }
    const claimReward = async () => {
        const transaction = await fairlaunchContract?.harvest(props.stakingDataItem.pool_id)
        await transaction.wait()
        props.updateAllData()
    }
    const disableDeposit = !(
        depositInput &&
        BigNumber.from(depositWei).gt(0) &&
        props.balance.gte(BigNumber.from(depositWei)) &&
        !props.allowance.isZero()
    )
    const disableWithdraw = !(
        withdrawInput &&
        BigNumber.from(withdrawWei).gt(0) &&
        props.depositBalance.gte(BigNumber.from(withdrawWei))
    )
    const disableClaim = props.stakingReward.isZero()

    // console.log('props.allowance', props.allowance)

    async function loadDataFromAPI() {
        // setWeight('0')
    }

    useEffect(() => {
        loadDataFromAPI()
    }, [])

    const handleApproveBtn = async () => {
        setLoading(true)
        await approve().finally(() => {
            setLoading(false)
        })
    }

    const handleDepositBtn = async () => {
        setLoading(true)
        await deposit().finally(() => {
            setLoading(false)
        })
        setDepositInput('')
    }

    const handleWithdrawBtn = async () => {
        setLoading(true)
        await withdraw().finally(() => {
            setLoading(false)
        })
        setWithdrawInput('')
    }

    const handleClaimBtn = async () => {
        setLoading(true)
        await claimReward().finally(() => {
            setLoading(false)
        })
    }

    const handleMaxDepositBtn = () => {
        setDepositInput(weiToFixed(props.balance.toString(), 18))
    }

    const handleMaxWithdrawBtn = () => {
        setWithdrawInput(weiToFixed(props.depositBalance.toString(), 18))
    }

    const wrappedOnDismiss = () => {
        console.log('')
    }

    return (
        <>
            <div className={`staking-item ${toggleShow ? 'active' : ''} mb-4`}>
                <Modal isOpen={loading} onDismiss={wrappedOnDismiss} maxHeight={90}>
                    <div className={`modal-loading ${isDark ? 'text-white' : 'text-black'}`}>
                        <div className="text-center">
                            <div className="spinner-border text-primary mx-auto mb-4" role="status"></div>
                            <div className="ml-3">Waiting for Transaction...</div>
                        </div>
                    </div>
                </Modal>
                <div className="staking-header row" onClick={() => setToggleShow(!toggleShow)}>
                    <div className="tokens-group col-5 d-flex">
                        <div className="d-flex align-items-center">
                            <div className="img-logo">
                                <img src={props.stakingDataItem.icon} alt="" />
                            </div>
                            <div className={`title-group`}>
                                <div className="text-title">{props.stakingDataItem.name} LPs</div>
                                {/* <a target="_blank" rel="noreferrer" href={props.stakingDataItem.add_lp_url}>
                                    {props.stakingDataItem.lp_address === `0x3806aae953a3a873D02595f76C7698a57d4C7A57` ?
                                        < div className="text-sub">{props.stakingDataItem.pool_id === 9 ? `Buy` : `Add`} {props.stakingDataItem.name}</div>
                                        : null
                                    }
                                </a> */}
                            </div>
                        </div>
                        <div className="weight-badge">{props.apiData && props.apiData.alloc_point}X</div>
                    </div>
                    <div className="apr-group col-2">
                        <div>
                            <div className="title">
                                APR : {props.apiData && numberWithCommasFixedZero(props.apiData.apr.toString())}%
                            </div>
                            <div className="sub">
                                ≈ {props.apiData && numberWithCommas((props.apiData.apr / 365).toFixed(2))}% daily
                            </div>
                        </div>
                    </div>
                    <div className="tvl-group col-3">
                        ${props.apiData && numberWithCommasFixedZero(props.apiData.tvl.toString())}
                    </div>
                    <div className="earned-group col-2">
                        {props.stakingReward.isZero() ? '0.00' : numberWithCommas(weiToFixed(props.stakingReward.toString(), 2))}
                    </div>
                </div>
                <hr className="staking-divider" />
                <div className="staking-body d-block">
                    <div className="row">
                        <div className="col-12 col-sm-9 d-block">
                            <div className="row">
                                <div className="col-12 px-4 px-sm-3 col-sm-6 mt-2 mt-sm-0 ">
                                    <div className="w-100">
                                        Balance:{' '}
                                        <span className="text-balance">
                                            {props.balance.isZero() ? '0' : weiToFixed(props.balance.toString(), 2)}
                                        </span>
                                    </div>
                                    <div className="w-100">
                                        <input
                                            type="number"
                                            placeholder="0.00"
                                            className="staking-input"
                                            value={depositInput}
                                            onChange={e => setDepositInput(e.target.value)}
                                        />
                                        <button onClick={handleMaxDepositBtn} className="btn-max">
                                            Max
                                        </button>
                                    </div>
                                    <div className="d-flex gap-1">
                                        <button
                                            onClick={handleApproveBtn}
                                            className={`btn-staking-confirm mb-sm-0 mb-4 ${!props.allowance.isZero() ? 'd-none' : ''}`}
                                        >
                                            Approve
                                        </button>
                                        <button
                                            onClick={handleDepositBtn}
                                            className={`btn-staking-confirm mb-sm-0 mb-4 ${disableDeposit ? 'disabled' : ''}`}
                                            disabled={disableDeposit}
                                        >
                                            Deposit
                                        </button>
                                    </div>
                                    <div className="w-100 text-center mt-2">
                                        {/* <a target="_blank" rel="noreferrer" href={props.stakingDataItem.add_lp_url} className="text-center w-100">
                                            {props.stakingDataItem.lp_address === `0x3806aae953a3a873D02595f76C7698a57d4C7A57` ?
                                                ``
                                                : <div className={`text-sub ${isDark ? `text-white` : `text-black`} d-inline`} style={{ fontSize: `0.75rem` }}> You don't have LP token yet? <div className="tokens-group text-sub d-inline">Add {props.stakingDataItem.name} LP</div></div>
                                            }
                                        </a> */}
                                    </div>
                                </div>
                                <div className="col-12 px-4 px-sm-3 col-sm-6">
                                    <div className="w-100">
                                        Deposited:{' '}
                                        <span className="text-balance">
                                            {props.depositBalance.isZero() ? '0' : weiToFixed(props.depositBalance.toString(), 2)}
                                        </span>
                                    </div>
                                    <div className="w-100">
                                        <input
                                            type="number"
                                            placeholder="0.00"
                                            className="staking-input"
                                            value={withdrawInput}
                                            onChange={e => setWithdrawInput(e.target.value)}
                                        />
                                        <button onClick={handleMaxWithdrawBtn} className="btn-max ">
                                            Max
                                        </button>
                                    </div>
                                    <div className="w-100">
                                        <button
                                            onClick={handleWithdrawBtn}
                                            disabled={disableWithdraw}
                                            className={`btn-staking-confirm btn-block ${disableWithdraw ? 'disabled' : ''}`}
                                        >
                                            Withdraw
                                        </button>
                                    </div>
                                    <div className="w-100 text-center mt-2">
                                        <a target="_blank" rel="noreferrer" href={props.stakingDataItem.remove_lp_url} className="text-center w-100">
                                            {props.stakingDataItem.lp_address === `0x3806aae953a3a873D02595f76C7698a57d4C7A57` ?
                                                ``
                                                : <div className={`text-sub ${isDark ? `text-white` : `text-black`} d-inline`}style={{ fontSize: `0.75rem` }}> You want to remove LP token? <div className="tokens-group text-sub d-inline">Remove {props.stakingDataItem.name} LP</div></div>
                                            }
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-4 px-sm-3 col-sm-3">
                            <div className="staking-reward">
                                <div className="title">
                                    {props.stakingReward.isZero()
                                        ? '0.00'
                                        : numberWithCommas(weiToFixed(props.stakingReward.toString(), 2))}
                                </div>
                                <div className="sub">
                                    (≈ $
                                    {props.stakingReward.isZero()
                                        ? '0.00'
                                        : numberWithCommas(
                                            (
                                                parseFloat(weiToFixed(props?.stakingReward?.toString(), 2)) *
                                                parseFloat(props?.twinPrice?.toString())
                                            ).toFixed(2)
                                        )}
                                    )
                                </div>
                                <button
                                    onClick={handleClaimBtn}
                                    disabled={disableClaim}
                                    className={`btn-claim mb-4 mb-sm-0 ${disableClaim ? 'disabled' : ''}`}
                                >
                                    Claim
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}