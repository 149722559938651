// import { ChainId, TokenAmount } from '@uniswap/sdk'
// import { ChainId } from '@uniswap/sdk'

import React, { useState } from 'react'
// import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import styled from 'styled-components'
import Logo from '../../assets/svg/logo-navbar.svg'
import LogoDark from '../../assets/svg/logo-navbar-dark.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { ExternalLink } from '../../theme'
import Settings from '../Settings'
import { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import Row from 'react-bootstrap/esm/Row'
import { useEffect } from 'react'
import BurgerMenu from 'components/Menu/BurgerMenu'
import { TwinPriceBurger, TwinBalance } from 'components/Twindex'
import { useTokenContract } from 'hooks/useContract'
import { BigNumber } from 'mathjs'
import { TWINDEX_TOKEN_ADDRESSES } from 'pages/Farm/constants'
import './index.css'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0.5rem 1rem;
    width: calc(100%);
    position: relative;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
        padding: 1.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  width: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: auto;
    max-width: 960px;
    padding: 0.5rem;
    // position: fixed;
    // bottom: 0px;
    // left: 0px;
    height: 72px;
    border-radius: 12px 12px 0 0;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.5rem;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-right: 5.5rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
    position: absolute;
    left: 0rem;
    margin-right: 0rem;
    width: 100%;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
   flex-direction: row-reverse;
    align-items: center;
    position: absolute;
    left: 1em;
    width: 90%;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  // display: contents;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  //  display: flex;
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 0rem 0rem;
    justify-content: flex-end;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: ${({ theme, active }) => (!active ? `rgb(229, 231, 235)` : theme.border6)};
  background-color: transparent;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  :focus {
    border: 1px solid #19c6bc;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 1rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-right: 0rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
      // width: 50%;
      // margin-left: 0.75rem;
      margin-right: 0rem;
  `};
  color: ${({ theme }) => theme.primary1};
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    color: ${({ theme }) => 'white'};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: left;
  `};
  :hover {
    cursor: pointer;
  }
`

const TwinDex = styled.div`
  transition: transform 0.3s ease;
  // :hover {
  //   transform: rotate(-5deg);
  // }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 0.5rem;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      margin: 0 0.25rem;
      font-size: 0.55rem;
`}
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
}) <{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 0.5rem;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: block;
      margin: 0 0.25rem;
      font-size: 0.55rem;
`}
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const [apiData, setApiData] = useState<any | null>(null)
  const [isDark] = useDarkModeManager()
  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const [balance, setBalance] = useState<BigNumber | null>(null)
  const tokenContract = useTokenContract(TWINDEX_TOKEN_ADDRESSES[chainId ? chainId : 56])
  const fetchData = () => {
    return fetch('https://api-v2.twindex.com/')
      .then(response => response.json())
      .then(data => {
        if (!apiData) setApiData(data)
      })
  }

  const update = async () => {
    if (account && tokenContract && !balance) {
      const result = await tokenContract.balanceOf(account)
      if (!balance && balance !== result) setBalance(result)
    }
  }

  useEffect(() => {
    fetchData()
    if (!balance) update()
    // Interval loop
    // const intervalId = setInterval(fetchData, 60000)
    // return () => clearInterval(intervalId)
  }, [])


  return (
    <HeaderFrame className="container mt-0 mt-lg-3">
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>

      <HeaderRow className="d-flex">
        <Title href=".">
          <TwinDex>
            <img src={isDark ? LogoDark : Logo} alt="logo" />
          </TwinDex>
        </Title>
        <div className="d-none d-lg-flex ml-auto desktop-header-right">
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }} className="d-none d-lg-flex ml-auto">
            {apiData && apiData.twin_price ? (
              <div className={`w-100`} style={{ marginRight: `0.75rem` }}>
                {TwinPriceBurger(apiData.twin_price, isDark)}
              </div>
            ) : null}
            {apiData && apiData.twin_price ? (
              <div className="w-100" style={{ marginRight: `-1rem` }}>
                {TwinBalance(balance, apiData.twin_price, 2)}
              </div>
            ) : null}
            <Web3Status />
          </AccountElement>
        </div>
        <div className="d-flex position-absolute" style={{ right: `1rem` }}>
          <BurgerMenu />
          <Settings />
        </div>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          <HideSmall>
            {/* {chainId && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            )} */}
          </HideSmall>
          <div className="w-100 d-unset d-lg-none">
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }} className="">
              {apiData && apiData.twin_price ? (
                <div className={`w-100`} style={{ marginRight: `0.75rem` }}>
                  {TwinPriceBurger(apiData.twin_price, isDark)}
                </div>
              ) : null}
              {apiData && apiData.twin_price ? (
                <div className="w-100" style={{ marginRight: `-1rem` }}>
                  {TwinBalance(balance, apiData.twin_price, 2)}
                </div>
              ) : null}
              <Web3Status />
            </AccountElement>
          </div>
        </HeaderElement>
        <HeaderElementWrap>
          <div className="d-none" style={{ position: 'absolute', right: '2rem' }}>
            <HeaderLinks>
              <StyledNavLink
                id={`pool-nav-link`}
                to={'/pool'}
                isActive={(match, { pathname }) =>
                  Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/create') ||
                  pathname.startsWith('/find')
                }
              ></StyledNavLink>
              <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
                {'Swap'}
              </StyledNavLink>
              <StyledNavLink
                id={`pool-nav-link`}
                to={'/pool'}
                isActive={(match, { pathname }) =>
                  Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/create') ||
                  pathname.startsWith('/find')
                }
              >
                {'Pool'}
              </StyledNavLink>
              <StyledNavLink id={`farm-nav-link`} to={'/farm'}>
                {'Farm'}
              </StyledNavLink>
              <StyledExternalLink
                id={`stake-nav-link`}
                href={'https://swap.arken.finance/#/market?filter=synthetic_assets'}
              >
                {'Trade'}
              </StyledExternalLink>
              <StyledNavLink id={`swap-nav-link`} to={'/mint'}>
                {'Mint'}
              </StyledNavLink>
              <StyledExternalLink id={`stake-nav-link`} href={'https://bridge.twindex.com/bridge'}>
                {'Bridge'}
              </StyledExternalLink>
              <StyledNavLink id={`farm-nav-link`} to={'/info'}>
                {'Info'}
              </StyledNavLink>
              <StyledExternalLink
                id={`stake-nav-link`}
                href={'https://docs.dopple.finance/twindex/tokenomics/usdtwin-twindex-token'}
              >
                {'Docs'}
              </StyledExternalLink>
              <StyledNavLink id={`swap-nav-link`} to={'/dashboard'}>
                {'Dashboard'}
              </StyledNavLink>
            </HeaderLinks>
          </div>
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
