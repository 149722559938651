// import { useTwindexBalanceOf } from 'pages/Farm/hooks'
import { filterSubGraph, getSubGraph } from 'hooks/subGraph'
import { SUB_GRAPH_DATA } from 'pages/Farm/types'
import React, { useEffect, useState } from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import './index.css'
import TwinLogo from '../../assets/images/logo.png'
import { bignumber } from 'mathjs'
import { stockTokens } from 'pages/Mint/stockTokens'
import { StockBalance, StockTokenWithPrice } from 'pages/Mint/types'
import { weiToFixed } from 'utils/math-helpers'
import mkrMulticall from 'utils/mkrMulticall'
import PRICE_FEED_ABI from '../../constants/abis/SyntheticStock/priceFeed.json'
import { numberWithCommas, useActiveWeb3React } from 'hooks'
import { ERC20_ABI } from 'constants/abis/erc20'
import { getProviderOrSigner } from 'utils'
import { Contract } from 'ethers'
import { STAKING_LIST, TWINDEX_TOKEN_ADDRESSES } from 'pages/Farm/constants'
import { useStakingDepositBalance, useStakingReward } from 'pages/Farm/hooks'
import { DashboardStakingItem } from './stakingCard'
import { useContract } from 'components/SearchModal/MintFunction'
import TWINDEX_TOKEN_ABI from 'constants/abis/twindex-token-abi.json'
import { StockWalletPopover } from './components'
import { BigNumber } from '@ethersproject/bignumber'

export default function DashboardPage() {
  const { account, chainId, library } = useActiveWeb3React()
  const [isDark] = useDarkModeManager()
  const [apiData, setApiData] = useState<any | null>(null)
  const [subGraphData, setSubGraphData] = useState<SUB_GRAPH_DATA | null>(null)
  const [oraclePriceList, setOraclePriceList] = useState<Array<StockTokenWithPrice>>()
  const [stockBalance, setStockBalance] = useState<Array<StockBalance>>()
  // const [priceList, setPriceList] = useState<StockTokenWithPrice[]>([])
  // const [userStocks, setUserStocks] = useState<Array<TLoan>>([])
  // const dfiContract = useDfiContract(DFI_PROTOCOL_CONTRACT_ADDRESSES)
  const [balance, setBalance] = useState<BigNumber | null>(null)

  const tokenContract = useContract(TWINDEX_TOKEN_ADDRESSES[chainId ? chainId : 56], TWINDEX_TOKEN_ABI)

  const update = async () => {
    if (account && tokenContract && !balance) {
      const result = await tokenContract.balanceOf(account)
      if (balance !== result) setBalance(result)
    }
  }

  const getSubGraphData = () =>
    getSubGraph()
      .then(res => {
        if (!subGraphData) setSubGraphData(res as SUB_GRAPH_DATA)
      })
      .catch(error => console.error('error: ', error))

  async function getStockBalance() {
    try {
      const balanceList: any[] = []
      stockTokens?.forEach(async token => {
        if (!library || !account) return
        const tokenContract = new Contract(token?.address, ERC20_ABI, getProviderOrSigner(library, account))
        const tokenBalance = await tokenContract?.balanceOf(account)
        const balance = weiToFixed(tokenBalance.toString(), 4)
        const tradingPrice = subGraphData
          ? parseFloat(filterSubGraph(token.symbol, subGraphData).tokenPrice0.toString()).toFixed(2)
          : 0
        balanceList.push({
          name: token?.name,
          symbol: token?.symbol,
          address: token?.address,
          price: tradingPrice,
          balance,
          logoURI: token?.logoURI
        })
        if ((!stockBalance || stockBalance.length <= 0) && balanceList.length >= stockTokens.length) {
          if (!stockBalance) setStockBalance(balanceList)
        }
      })
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const calls = stockTokens.map(st => {
    return {
      address: '0xd4f061a6a276f8B0Ae83D210D838B45fCC7532B2',
      name: 'queryRate',
      params: [st.address, '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5']
    }
  })

  const fetchOraclePrice = () => {
    mkrMulticall(chainId, PRICE_FEED_ABI, calls).then(result => {
      const results: StockTokenWithPrice[] = []
      stockTokens.map((stock, index: number) => {
        results.push({
          ...stock,
          price: parseFloat(weiToFixed(bignumber(result[index].rate.toString()), 6))
        })
        return stock
      })
      if (!oraclePriceList) setOraclePriceList(results)
      return results
    })
  }

  const { depositBalanceResult, update: updateDepositBalance } = useStakingDepositBalance()
  const { stakingRewardResult, totalUnclaimReward, update: updateStakingReward } = useStakingReward()

  const updateAllData = () => {
    updateDepositBalance()
    updateStakingReward()
  }

  const fetchData = () => {
    return fetch('https://api.twindex.com/')
      .then(response => response.json())
      .then(data => {
        if (!apiData) setApiData(data)
      })
  }

  useEffect(() => {
    if (!apiData) fetchData()
  }, [])

  useEffect(() => {
    if (!depositBalanceResult) updateDepositBalance()
    if (!totalUnclaimReward) updateStakingReward()
    if (!subGraphData) getSubGraphData()
    if (!oraclePriceList) fetchOraclePrice()
    if (!stockBalance) getStockBalance()
    if (!balance) update()
    // Interval loop
    // setInterval(updateAllData, 10000)
    // setInterval(fetchData, 60000)
  }, [balance])
  return (
    <>
      <div className={`${isDark ? 'dark' : 'light'} container dashboard`}>
        <div className="row text-center">
          {/* My Twindex wallet balance */}
          <div className="col-12">
            <div className="card wallet">
              <div className="row">
                <h4 className="col-12 mb-4">My Twindex wallet balance</h4>
                <div className={`col-12 col-sm-6 float-lg-left `}>
                  <img src={TwinLogo} alt="TwinLogo" className="twin-logo" />
                </div>
                <div className="col-12 col-sm-6 float-lg-right pt-5 pb-5">
                  <div className="balance">{`${numberWithCommas(weiToFixed(balance?.toString(), 2))}`}</div>
                  <div className="balance-value">
                    ≈ $
                    {`${apiData && (apiData.twin_price * parseFloat(weiToFixed(balance?.toString(), 2))).toFixed(2)}`}
                  </div>
                  <button type="button" className="buy-twin-btn mt-3 float-right">
                    Buy Twin
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Top gainers */}
          {/* <div className="col-12 col-sm-7 mt-3 mt-sm-0 d-none">
            <div className="card top-gainers pd-0 pd-lg-5">
              <div className="row">
                <h4 className="text-left">Top Gainers</h4>
              </div>
              <div className="row">
                <table className="col-12">
                  <tr className="spaceUnder">
                    <th className={``}>Tickers</th>
                    <th className={``}>Twindex Price</th>
                    <th className={``}>Oracle Price</th>
                    <th className={`d-none d-sm-block`}>Premium / Discount</th>
                    <th className={``}>Action</th>
                  </tr>
                  {subGraphData ? oraclePriceList?.slice(oraclePriceList.length - 6, oraclePriceList.length).map((stock, index) => (
                    TopGainerRow(subGraphData, stock, index)
                  )) : ``}
                </table>
              </div>
            </div>
          </div> */}
        </div>

        {/* My total stocks wallet */}
        <div className="row mt-3">
          <div className="col-12">
            <div className="card stock-wallet">
              <h4 className="mb-3">My total stocks wallet</h4>
              <div className="row">
                {stockBalance?.map(stock => stock.balance <= 0).length === 0 ? (
                  <>
                    <div className="col-12">
                      <div className="no-stock-in-wallet-wrapper">It’s empty here! you get your first one by</div>
                    </div>
                  </>
                ) : (
                  ``
                )}
                {stockBalance
                  ? stockBalance?.map(stock =>
                    stock.balance <= 0 ? (
                      ``
                    ) : (
                      <div className="col-12 col-sm-6">
                        <div className="stock-wrapper mb-3 p-4">
                          <div className="row text-sm-center">
                            <div className="col-12 col-sm-2">
                              <img src={stock.logoURI} alt={`${stock.name}-logo`} className="logo" />
                              {/* <div className="r">{stock.name}</div> */}
                            </div>

                            <div className="col-12 col-sm-3">
                              <div className="name-1 mb-2">{stock.name}</div>
                              <div className="name-2">{stock.name}</div>
                            </div>

                            <div className="col-12 col-sm-4">
                              <div className="balance-1 mb-2">{stock.balance}</div>
                              <div className="balance-2">${(stock.balance * stock.price).toFixed(2)}</div>
                            </div>

                            <div className="col-12 col-sm-3">
                              <div className="grey mb-2 text-center">{`Action`}</div>
                              {/* <div className="header-2"> */}
                              <StockWalletPopover />
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )
                  : ``}
              </div>
            </div>
          </div>
        </div>

        {/* My farms */}
        {/* <div className="staking-list"> */}
        <div className="row mt-3">
          <div className="col-12">
            <div className="card">
              <h4 className="mb-3">My Farms</h4>
              <div className="row">
                {STAKING_LIST.map((sl, i) =>
                  depositBalanceResult.length && !depositBalanceResult[i].balance.isZero() ? (
                    <div key={sl.name} className={`col-sm-6`}>
                      <DashboardStakingItem
                        stakingDataItem={sl}
                        allowance={BigNumber.from(0)}
                        balance={BigNumber.from(0)}
                        depositBalance={
                          depositBalanceResult.length ? depositBalanceResult[i].balance : BigNumber.from(0)
                        }
                        stakingReward={stakingRewardResult.length ? stakingRewardResult[i].reward : BigNumber.from(0)}
                        updateAllData={updateAllData}
                        apiData={apiData && apiData.lp_data[sl.lp_address]}
                        twinPrice={`0`}
                      />
                    </div>
                  ) : (
                    ``
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
