import React from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import bscLogo from '../../assets/svg/bsc-icon.svg'
import telegramLogo from '../../assets/svg/telegram-icon.svg'
import telegramDarkLogo from '../../assets/svg/telegram-dark-icon.svg'
import twitterLogo from '../../assets/svg/twitter-icon.svg'
import chainLinkLogo from 'assets/images/ChainLink.png'
import { FOOTER_DESKTOP, FOOTER_MOBILE } from 'components/Footer/constants'
import twinBanner from 'assets/images/footer-banner.png'
import twinBannerDark from 'assets/images/footer-banner-dark.png'
import './index.css'

export default function FooterLanding() {
  const [isDark] = useDarkModeManager()
  // window.scrollTo(0, document.body.scrollHeight)
  return (
    <div className={`${isDark ? `dark` : `light`} `}>
      {/* Desktop */}
      <div className={`d-none d-md-block pr-5 pl-5 ${isDark ? `dark` : `light`} footer-wrapper`}>
        <div className={``} style={{ maxWidth: `100%` }}>
          <div className="container">
            <div className="row">
              <div className="col-8">
                <div className="row">
                  {FOOTER_DESKTOP.header.map(header => (
                    <div className={`col-3 header font-weight-bold mb-3`} key={`LANDING_SECTION11-1-${header}`}>
                      {header}
                    </div>
                  ))}
                  {FOOTER_DESKTOP.menu.map((menu, index) => (
                    <div
                      className={`col-3 body mb-2`}
                      key={`LANDING_SECTION11-2-${index}`}
                      onClick={() => (menu.href ? (window.location.href = menu.href) : ``)}
                    >
                      {menu.text}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-4">
                <div className={`d-flex container ${isDark ? `dark` : `light`} mobile-footer-wrapper-2 float-right`}>
                  <div className="container">
                    <img src={isDark ? twinBannerDark : twinBanner} alt="twinBanner" className="mb-3 w-100" />
                    <div className="d-flex float-right">
                      <div className="twitter" onClick={() => (window.location.href = `http://twitter.com/twindexcom`)}>
                        <img src={twitterLogo} alt="twitterLogo" className="" />
                        <span className="body">Twitter</span>
                      </div>
                      <div className="telegram" onClick={() => (window.location.href = `https://t.me/twindex`)}>
                        <img src={isDark ? telegramDarkLogo : telegramLogo} alt="telegramLogo" className="" />
                        <span className="body">Telegram</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-8 text-left">
              <div className={`${isDark ? `text-white` : `text-black`}`} style={{ fontWeight: 500 }}>
                <div className="d-inline mr-2">Powered by</div>
                <img
                  src={chainLinkLogo}
                  alt="chainLinkLogo"
                  style={{ height: `2rem`, width: `auto` }}
                  className="mr-2"
                />
                <div className="d-inline mr-3">Chainlink</div>
                <img src={bscLogo} alt="bscLogo" style={{ height: `2rem`, width: `auto` }} className="mr-2" />
                <div className="d-inline">Binance Smart Chain</div>
              </div>
            </div>
          </div>

          {/* <div className="col-4 text-right">
            <div
              className="d-inline"
              style={{ cursor: `pointer` }}
              onClick={() => (window.location.href = `https://t.me/twindex`)}
            >
              <img
                src={isDark ? telegramDarkLogo : telegramLogo}
                alt="telegramLogo"
                style={{ height: `23px`, width: `auto` }}
              />
              <div className={`font-weight-bold ml-2 d-inline ${isDark ? `text-white` : `text-black`}`}>Telegram</div>
            </div>
            <div
              className="ml-3 d-inline"
              style={{ cursor: `pointer` }}
              onClick={() => (window.location.href = `http://twitter.com/twindexcom`)}
            >
              <img src={twitterLogo} alt="twitterLogo" style={{ height: `20px`, width: `auto` }} className="d-inline" />
              <div className={`font-weight-bold ml-2 d-inline ${isDark ? `text-white` : `text-black`}`}>Twitter</div>
            </div>
          </div> */}
        </div>
      </div>

      {/* Mobile */}
      <div className="d-block d-md-none">
        <div className={`d-flex container ${isDark ? `dark` : `light`} mobile-footer-wrapper`}>
          <div className="d-flex container pr-5 pl-5">
            <div className="w-100">
              <div className="header">Resourses</div>
              {FOOTER_MOBILE.RESOURCES.map(link => (
                <div className="link" onClick={() => (window.location.href = link.href)} key={link.text}>
                  {link.text}
                </div>
              ))}
            </div>

            <div className="w-100">
              <div className="header">Developers</div>
              {FOOTER_MOBILE.DEVELOPERS.map(link => (
                <div className="link" onClick={() => (window.location.href = link.href)} key={link.text}>
                  {link.text}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={`d-flex container ${isDark ? `dark` : `light`} mobile-footer-wrapper`}>
          <div className="d-flex container pr-5 pl-5">
            <div className="w-100">
              <div className="header">Our Features</div>
              {FOOTER_MOBILE.OUR_FEATURES.map(link => (
                <div className="link" onClick={() => (window.location.href = link.href)} key={link.text}>
                  {link.text}
                </div>
              ))}
            </div>

            <div className="w-100">
              <div className="header">Contact</div>
              {FOOTER_MOBILE.CONTACT.map(link => (
                <div className="link" onClick={() => (window.location.href = link.href)} key={link.text}>
                  {link.text}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="d-flex container mobile-footer-wrapper-2">
          <div className="container pr-5 pl-5">
            <img src={isDark ? twinBannerDark : twinBanner} alt="twinBanner" className="mb-3 mt-5 w-100" />
            <div className="d-flex">
              <div className="twitter" onClick={() => (window.location.href = `http://twitter.com/twindexcom`)}>
                <img src={twitterLogo} alt="twitterLogo" className="" />
                <span className="body">Twitter</span>
              </div>
              <div className="telegram" onClick={() => (window.location.href = `https://t.me/twindex`)}>
                <img src={isDark ? telegramDarkLogo : telegramLogo} alt="telegramLogo" className="" />
                <span className="body">Telegram</span>
              </div>
            </div>
          </div>
        </div>

        <div className={`d-flex container mobile-footer-wrapper-3 pb-5`}>
          <div className="container pr-5 pl-5">
            <div className="powered">Powered by</div>
            <div className="d-flex">
              <div className="chain-link">
                <img src={chainLinkLogo} alt="chainLinkLogo" className="" />
                <span className="text">Chainlink</span>
              </div>
              <div className="bsc ml-3">
                <img src={bscLogo} alt="bscLogo" className="" />
                <span className="text text-truncate">Binance Smart Chain</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
