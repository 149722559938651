import React from 'react'
import twinLogo from 'assets/svg/twin-price-icon.svg'
import './index.css'
import { numberWithCommas } from 'hooks'
import { weiToFixed } from 'utils/math-helpers'

export function TwinPriceBurger(price: any, isDark: boolean) {
  return (
    <div className={`twin-price-wrapper ${isDark ? `text-white` : `text-black`}`}>
      <div className="d-flex">
        <div className="">
          <img src={twinLogo} alt="TwinSmall" className="TwinSmall inline" />
        </div>
        <div className="pl-1">
          <div className="price">Price</div>
          <div className="value text-truncate">${parseFloat(price).toFixed(2)}</div>
        </div>
      </div>
    </div>
  )
}

export function TwinBalance(balance: any, twin_price: any, paddingRight = 0) {
  return (
    <div className={`w-100 pr-0 pl-1 twin-balance-wrapper`}>
      <div className="balance w-100 text-truncate" style={{ paddingRight: `${paddingRight}rem` }}>
        {numberWithCommas(weiToFixed(balance?.toString(), 2))} TWIN
      </div>
      <div className="balance-value text-truncate">
        {`≈ $` + (twin_price * parseFloat(weiToFixed(balance?.toString(), 2))).toFixed(2)}
      </div>
    </div>
  )
}
