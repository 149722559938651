import dopTwinIcon from "../../assets/images/staking/dop-twin.svg";
import dopTslaIcon from "../../assets/images/staking/dop-tsla.svg";
import dopAaplIcon from "../../assets/images/staking/dop-aapl.svg";
import dopAmzaIcon from "../../assets/images/staking/dop-amza.svg";
import dopGooglIcon from "../../assets/images/staking/dop-googl.svg";
import dollyTslaIcon from "../../assets/images/staking/dolly-tsla.svg";
import dollyAaplIcon from "../../assets/images/staking/dolly-aapl.svg";
import dollyAmzaIcon from "../../assets/images/staking/dolly-amza.svg";
import dollyGooglIcon from "../../assets/images/staking/dolly-googl.svg";
import twinIcon from "../../assets/images/staking/twin.svg";
import twinBnbIcon from "../../assets/images/staking/twin-bnb.svg";
import twinDollyIcon from "../../assets/images/staking/twin-dolly.svg";
import { TStakingData } from "./types";
import { ChainId } from "@uniswap/sdk";
import dollyCoinIcon from "../../assets/images/staking/dolly-coin.svg";
import dollyBaiduIcon from "../../assets/images/staking/dolly-baidu.svg";
import dollySpceIcon from "../../assets/images/staking/dolly-spce.svg";
import dollySpyIcon from "../../assets/images/staking/dolly-spy.svg";
import dollyXauIcon from "../../assets/images/staking/dolly-xau.svg";

export const STAKING_LIST = [
  {
    name: "TWIN",
    add_lp_url:
      "https://exchange.twindex.com/#/swap?outputCurrency=0x3806aae953a3a873D02595f76C7698a57d4C7A57",
    remove_lp_url:
      "https://exchange.twindex.com/#/swap?outputCurrency=0x3806aae953a3a873D02595f76C7698a57d4C7A57",
    address: "",
    lp_address: "0x3806aae953a3a873D02595f76C7698a57d4C7A57",
    icon: twinIcon,
    pool_id: 9,
    weight: 10,
  } as TStakingData,
  {
    name: "TWIN-DOLLY",
    add_lp_url:
      "https://twindex.com/#/add/0x3806aae953a3a873D02595f76C7698a57d4C7A57/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x3806aae953a3a873D02595f76C7698a57d4C7A57/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    address: "",
    lp_address: "0x593747Cd0023669c7A67511406266559B166Ca5d",
    icon: twinDollyIcon,
    pool_id: 10,
    weight: 15,
  } as TStakingData,
  {
    name: "TWIN-BNB",
    add_lp_url:
      "https://twindex.com/#/add/0x3806aae953a3a873D02595f76C7698a57d4C7A57/BNB",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x3806aae953a3a873D02595f76C7698a57d4C7A57/BNB",
    address: "",
    lp_address: "0xC3BFdd2cd502e719132Bf7aA5954C74e9C7209F5",
    icon: twinBnbIcon,
    pool_id: 11,
    weight: 15,
  } as TStakingData,
  {
    name: "DOP-TWIN",
    add_lp_url:
      "https://twindex.com/#/add/0x3806aae953a3a873D02595f76C7698a57d4C7A57/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x3806aae953a3a873D02595f76C7698a57d4C7A57/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0x65A95C2BC5c12E8e30e24D322ff386249c29a072",
    icon: dopTwinIcon,
    pool_id: 0,
    weight: 20,
  } as TStakingData,
  {
    name: "DOLLY-dXAU",
    add_lp_url:
      "https://twindex.com/#/add/0x76a9ebf68d4a383B57a41a528dF51CC89bF8c931/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x76a9ebf68d4a383B57a41a528dF51CC89bF8c931/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    address: "",
    lp_address: "0xdAD3b2B345f7f3A8dDE56974240938Df43B61898",
    icon: dollyXauIcon,
    pool_id: 16,
    weight: 5,
  } as TStakingData,
  {
    name: "DOLLY-dSPY",
    add_lp_url:
      "https://twindex.com/#/add/0xf2018b59F8f9BE020C12Cb0A2624200d9FBa2af1/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0xf2018b59F8f9BE020C12Cb0A2624200d9FBa2af1/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    address: "",
    lp_address: "0x5d704aA3f49c57f0Da3b1db97114763cBD679333",
    icon: dollySpyIcon,
    pool_id: 15,
    weight: 5,
  } as TStakingData,
  {
    name: "DOLLY-dCOIN",
    add_lp_url:
      "https://twindex.com/#/add/0xB23DC438b40cDb8a625Dc4f249734811F7DA9f9E/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0xB23DC438b40cDb8a625Dc4f249734811F7DA9f9E/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    address: "",
    lp_address: "0x13F65fA2E9c9532125b752ED7A57Da87B4Dd4279",
    icon: dollyCoinIcon,
    pool_id: 12,
    weight: 5,
  } as TStakingData,
  {
    name: "DOLLY-dBIDU",
    add_lp_url:
      "https://twindex.com/#/add/0x48D2854529183e1de3D36e29D437f8F6043AcE17/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x48D2854529183e1de3D36e29D437f8F6043AcE17/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    address: "",
    lp_address: "0x65e79848699E9ef894C440206946087d31f6575C",
    icon: dollyBaiduIcon,
    pool_id: 13,
    weight: 5,
  } as TStakingData,
  {
    name: "DOLLY-dSPCE",
    add_lp_url:
      "https://twindex.com/#/add/0x75bD0500548B49455D2Dfd86fa30Fba476Cb3895/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x75bD0500548B49455D2Dfd86fa30Fba476Cb3895/0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5",
    address: "",
    lp_address: "0xb07fC35D2318bF8FF31A3FdDF1e758e3450D98e2",
    icon: dollySpceIcon,
    pool_id: 14,
    weight: 5,
  } as TStakingData,
  {
    name: "DOLLY-dTSLA",
    add_lp_url:
      "https://twindex.com/#/add/0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4/0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4/0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5",
    address: "",
    lp_address: "0xbde3b88c4D5926d5236447D1b12a866f1a38B2B7",
    icon: dollyTslaIcon,
    pool_id: 4,
    weight: 5,
  } as TStakingData,
  {
    name: "DOLLY-dAAPL",
    add_lp_url:
      "https://twindex.com/#/add/0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3/0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3/0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5",
    address: "",
    lp_address: "0xb91d34BCdF77E13f70AfF4d86129d13389dE0802",
    icon: dollyAaplIcon,
    pool_id: 6,
    weight: 5,
  } as TStakingData,
  {
    name: "DOLLY-dAMZN",
    add_lp_url:
      "https://twindex.com/#/add/0x1085B90544ff5C421D528aAF79Cc65aFc920aC79/0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x1085B90544ff5C421D528aAF79Cc65aFc920aC79/0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5",
    address: "",
    lp_address: "0x15C53425bd0b9bfEd3d4cCf27F4c4f1f7bBC838B",
    icon: dollyAmzaIcon,
    pool_id: 5,
    weight: 5,
  } as TStakingData,
  {
    name: "DOLLY-dGOOGL",
    add_lp_url:
      "https://twindex.com/#/add/0x9C169647471C1C6a72773CfFc50F6Ba285684803/0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x9C169647471C1C6a72773CfFc50F6Ba285684803/0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5",
    address: "",
    lp_address: "0xC38150a12D3C686f13D6e3A791d6301ed274B862",
    icon: dollyGooglIcon,
    pool_id: 7,
    weight: 5,
  } as TStakingData,
  {
    name: "DOP-dTSLA",
    add_lp_url:
      "https://twindex.com/#/add/0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0xb611aCe852f60F0ec039f851644a5bC5270AbF7b",
    icon: dopTslaIcon,
    pool_id: 1,
    weight: 10,
  } as TStakingData,
  {
    name: "DOP-dAAPL",
    add_lp_url:
      "https://twindex.com/#/add/0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0x2D4980c63962d4B9156a8974AEA7C7fd3121913A",
    icon: dopAaplIcon,
    pool_id: 2,
    weight: 10,
  } as TStakingData,
  {
    name: "DOP-dAMZN",
    add_lp_url:
      "https://twindex.com/#/add/0x1085B90544ff5C421D528aAF79Cc65aFc920aC79/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x1085B90544ff5C421D528aAF79Cc65aFc920aC79/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0x4a1135768C6ce4b2a2F20DAc80DE661949161627",
    icon: dopAmzaIcon,
    pool_id: 3,
    weight: 10,
  } as TStakingData,
  {
    name: "DOP-dGOOGL",
    add_lp_url:
      "https://twindex.com/#/add/0x9C169647471C1C6a72773CfFc50F6Ba285684803/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x9C169647471C1C6a72773CfFc50F6Ba285684803/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0x7A00B2BB049176C9C74E5d7bF617F84dB4763aec",
    icon: dopGooglIcon,
    pool_id: 8,
    weight: 10,
  } as TStakingData,
];

export const LEGACY_STAKING_LIST = [
  {
    name: "DOP-dTSLA",
    add_lp_url:
      "https://twindex.com/#/add/0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0xb611aCe852f60F0ec039f851644a5bC5270AbF7b",
    icon: dopTslaIcon,
    pool_id: 1,
    weight: 10,
  } as TStakingData,
  {
    name: "DOP-dAAPL",
    add_lp_url:
      "https://twindex.com/#/add/0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0x2D4980c63962d4B9156a8974AEA7C7fd3121913A",
    icon: dopAaplIcon,
    pool_id: 2,
    weight: 10,
  } as TStakingData,
  {
    name: "DOP-dAMZN",
    add_lp_url:
      "https://twindex.com/#/add/0x1085B90544ff5C421D528aAF79Cc65aFc920aC79/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x1085B90544ff5C421D528aAF79Cc65aFc920aC79/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0x4a1135768C6ce4b2a2F20DAc80DE661949161627",
    icon: dopAmzaIcon,
    pool_id: 3,
    weight: 10,
  } as TStakingData,
  {
    name: "DOP-dGOOGL",
    add_lp_url:
      "https://twindex.com/#/add/0x9C169647471C1C6a72773CfFc50F6Ba285684803/0x844FA82f1E54824655470970F7004Dd90546bB28",
    remove_lp_url:
      "https://exchange.twindex.com/#/remove/0x9C169647471C1C6a72773CfFc50F6Ba285684803/0x844FA82f1E54824655470970F7004Dd90546bB28",
    address: "",
    lp_address: "0x7A00B2BB049176C9C74E5d7bF617F84dB4763aec",
    icon: dopGooglIcon,
    pool_id: 8,
    weight: 10,
  } as TStakingData,
];

export const TWINDEX_TOKEN_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: "",
  [ChainId.RINKEBY]: "",
  [ChainId.ROPSTEN]: "",
  [ChainId.GÖRLI]: "",
  [ChainId.KOVAN]: "",
  [ChainId.BSC]: "0x3806aae953a3a873D02595f76C7698a57d4C7A57",
  [ChainId.TESTNET]: "0x3806aae953a3a873D02595f76C7698a57d4C7A57",
};

export const FAIRLAUNCH_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: "",
  [ChainId.RINKEBY]: "",
  [ChainId.ROPSTEN]: "",
  [ChainId.GÖRLI]: "",
  [ChainId.KOVAN]: "",
  [ChainId.BSC]: "0xe6bE78800f25fFaE4D1db7CA6d3485629bD200Ed",
  [ChainId.TESTNET]: "0xDa0a175960007b0919DBF11a38e6EC52896bddbE",
};

// export const QUERY_ALL = {
//   query: `{\n
//     TWIN_DOLLY:pair(id: "0x593747cd0023669c7a67511406266559b166ca5d"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n
//     TWIN_BNB:pair(id: "0xc3bfdd2cd502e719132bf7aa5954c74e9c7209f5"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n
//     DOP_TWIN: pair(id: "0x65a95c2bc5c12e8e30e24d322ff386249c29a072"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n
//     DOP_TWIN: pair(id: "0x65a95c2bc5c12e8e30e24d322ff386249c29a072"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dTSLA: pair(id: "0xbde3b88c4d5926d5236447d1b12a866f1a38b2b7"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dXAU: pair(id: "0xdad3b2b345f7f3a8dde56974240938df43b61898"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dSPY: pair(id: "0x5d704aa3f49c57f0da3b1db97114763cbd679333"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dCOIN: pair(id: "0x13f65fa2e9c9532125b752ed7a57da87b4dd4279"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dBIDU: pair(id: "0x65e79848699e9ef894c440206946087d31f6575c"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dSPCE: pair(id: "0xb07fc35d2318bf8ff31a3fddf1e758e3450d98e2"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dAAPL: pair(id: "0xb91d34bcdf77e13f70aff4d86129d13389de0802"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dAMZN: pair(id: "0x15c53425bd0b9bfed3d4ccf27f4c4f1f7bbc838b"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//     DOLLY_dGOOGL: pair(id: "0xc38150a12d3c686f13d6e3a791d6301ed274b862"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
//   }`,
//   variables: null,
// };


export const QUERY_ALL = {
  query: `{\n
    TWIN_DOLLY:pair(id: "0x593747cd0023669c7a67511406266559b166ca5d"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n
    TWIN_BNB:pair(id: "0xc3bfdd2cd502e719132bf7aa5954c74e9c7209f5"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n
    DOP_TWIN: pair(id: "0x65a95c2bc5c12e8e30e24d322ff386249c29a072"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n
    DOP_TWIN: pair(id: "0x65a95c2bc5c12e8e30e24d322ff386249c29a072"){\n    token0{\n      derivedUSD\n    }\n    token1{\n      derivedUSD\n    }\n  }\n
    KUSD_dTSLA: pair(id: "0x1f76ba5147ed58b64f98297e99ef681c5216380b"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
    KUSD_dXAU: pair(id: "0x1a2e0c99e19d505bb35d5ad4a30dd79d785a546f"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
    KUSD_dSPY: pair(id: "0x7e914eb2ca8fa5857d95396ea981f0da40a919a0"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
    KUSD_dCOIN: pair(id: "0x68db73154831c4744aae77de60f0549c9cd07404"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
    KUSD_dBIDU: pair(id: "0xd25894a5fef1d18982ab38960d25c7522b256b96"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
    KUSD_dSPCE: pair(id: "0xcb078b0dd31cb28eb9e653ea5628987017bdfd1f"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
    KUSD_dAAPL: pair(id: "0xba4663df0750a06b59b7ab937b47853c1a3c6219"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
    KUSD_dAMZN: pair(id: "0x5b1b798c652643440553f0c1ee484fd6526015b8"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
    KUSD_dGOOGL: pair(id: "0x7ac5a1ddc55d514454e139d28095de1d976ddb0c"){\n    reserve0{\n      derivedUSD\n    }\n    reserve1{\n      derivedUSD\n    }\n  }\n
  }`,
  variables: null
}