import React from 'react'
import './index.css'

export const PercentageBar = (props: { activeNumber: number; setActiveNumber: React.Dispatch<any> }) => {
  const activeNumber = props.activeNumber
  const setActiveNumber = props.setActiveNumber
  return (
    <div className="percentage-bar-wrapper d-flex">
      <div className={`btn-25 bar w-100 ${activeNumber === 25 && `active`}`} onClick={() => setActiveNumber(25)}>
        25%
      </div>
      <div className={`btn-50 bar w-100 ${activeNumber === 50 && `active`}`} onClick={() => setActiveNumber(50)}>50%</div>
      <div className={`btn-75 bar w-100 ${activeNumber === 75 && `active`}`} onClick={() => setActiveNumber(75)}>75%</div>
      <div className={`max bar w-100 ${activeNumber === 100 && `active`}`} onClick={() => setActiveNumber(100)}>MAX</div>
    </div>
  )
}
