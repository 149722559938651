export const FOOTER_DESKTOP = {
    header: [`Resources`, `Our Features`, `Developers`, `Contact`],
    menu: [
        {text: `Dashboard`, href: `#/`},{text: `Swap`, href: `#/swap`},{text: `Documentation`, href: `https://dopple-ecosystem.medium.com/twindex-2-0-the-worlds-first-fractional-algorithmic-synthetic-assets-4d723c9673b5`},{text: `Join the community`, href: `https://t.me/twindexAnn`},
        {text: `Whitepaper`, href: `https://dopple-ecosystem.medium.com/twindex-2-0-the-worlds-first-fractional-algorithmic-synthetic-assets-4d723c9673b5`},{text: `Pool`, href: `#/pool`},{text: `Info`, href: `#/info`},{text: ``, href: ``},
        {text: `FAQs`, href: `#/`},{text: `Farm`, href: `#/farm`},{text: `Addresses`, href: `#/`},{text: ``, href: ``},
        {text: ``, href: ``},{text: `Mint`, href: `#/mint`},{text: ``, href: ``},{text: ``, href: ``}
    ]
}

export const FOOTER_MOBILE = {
    RESOURCES: [{text: `Dashboard`, href: ``},{text: `Whitepaper`, href: `https://dopple-ecosystem.medium.com/twindex-2-0-the-worlds-first-fractional-algorithmic-synthetic-assets-4d723c9673b5`},{text: `FAQs`, href: `#/`}],
    DEVELOPERS: [{text: `Documentation`, href: `https://dopple-ecosystem.medium.com/twindex-2-0-the-worlds-first-fractional-algorithmic-synthetic-assets-4d723c9673b5`},{text: `Addresses`, href: `#/`},{text: `Info`, href: `#/info`}],
    OUR_FEATURES: [{text: `Swap`, href: `#/swap`},{text: `Pool`, href: `#/pool`},{text: `Farm`, href: `#/farm`},{text: `Mint`, href: `#/mint`}],
    CONTACT: [{text: `Join the community`, href: `https://t.me/twindexAnn`}]
}