import { toWei, toWeiDecimals } from 'utils/math-helpers'
import { Unit } from 'web3-utils'

export function useWeiAmount(amount: any, unit: Unit = 'ether') {
  return toWei(amount, unit)
}

export function useWeiAmountDecimals(amount: any, decimals: number) {
  try {
    return toWeiDecimals(amount, decimals);
  } catch (error) {
    return "0";
  }
}