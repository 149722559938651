export const emptyStockToken = {
  chainId: 0,
  address: '',
  dlAddress: '',
  name: '',
  symbol: '',
  decimals: 0,
  logoURI: ''
}

export const DOLLY = {
  ADDRESS: `0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5`,
  DL_ADDRESS: `0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5`
}
export const TSLA = {
  ADDRESS: `0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4`,
  DL_ADDRESS: `0x066888951a12E0b7fBc20ECF273075C3b7EE3db2`
}
export const AAPL = {
  ADDRESS: `0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3`,
  DL_ADDRESS: `0x0fEC9c00e0f2192d040564d2387E3f6EAB39d1a3`
}
export const AMZN = {
  ADDRESS: `0x1085B90544ff5C421D528aAF79Cc65aFc920aC79`,
  DL_ADDRESS: `0xc53cBC3996030019a881F29a4163a6b618e8E8B0`
}
export const GOOGL = {
  ADDRESS: `0x9C169647471C1C6a72773CfFc50F6Ba285684803`,
  DL_ADDRESS: `0x5DAE0803ee28FE9dca874361b085d559c28d06BC`
}
export const COIN = {
  ADDRESS: `0xB23DC438b40cDb8a625Dc4f249734811F7DA9f9E`,
  DL_ADDRESS: `0x42eebAaAd32A1844AE9A00E8Ef08f753c7c1F80F`
}
export const BIDU = {
  ADDRESS: `0x48D2854529183e1de3D36e29D437f8F6043AcE17`,
  DL_ADDRESS: `0x71bb710ff36D990FBcDAeBdd17f25bF86445cE6d`
}
export const SPCE = {
  ADDRESS: `0x75bD0500548B49455D2Dfd86fa30Fba476Cb3895`,
  DL_ADDRESS: `0x91206D4a553A61dC6815F5Bfa38E0DCdc1B9F477`
}
export const SPY = {
  ADDRESS: `0xf2018b59F8f9BE020C12Cb0A2624200d9FBa2af1`,
  DL_ADDRESS: `0x5B032B3C1ea40a5240d65046b670964BfCbEbf30`
}
export const XAU = {
  ADDRESS: `0x76a9ebf68d4a383B57a41a528dF51CC89bF8c931`,
  DL_ADDRESS: `0x4A18C52B40195485F86Fd1534427cc863b15E82b`
}


export const DFI_PROTOCOL = {
  ADDRESS: `0x37f5a7D8bBB1cc0307985D00DE520fE30630790c`
}
export const PRICE_FEED = {
  ADDRESS: `0x9C169647471C1C6a72773CfFc50F6Ba285684803`
}
export const LOAN_TOKEN_LOGIC_STANDARD = {
  ADDRESS: `0x6e0C17f56d0d97f83d400A66C508307Bce6f8E38`
}

// export const _ADDRESS = ``

export const dollyToken = {
  chainId: 56,
  address: `${DOLLY.ADDRESS}`,
  name: 'DOLLY',
  symbol: 'DOLLY',
  decimals: 18,
  logoURI:
    'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0xA977b72BB3063D2e013C28aC048b87160f55efFB.png'
}

export const stockTokens = [
  {
    chainId: 56,
    address: TSLA.ADDRESS,
    dlAddress: TSLA.DL_ADDRESS,
    name: 'TSLA',
    symbol: 'TSLA',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x17aCe02e5C8814BF2EE9eAAFF7902D52c15Fb0f4.svg'
  },
  {
    chainId: 56,
    address: AAPL.ADDRESS,
    dlAddress: AAPL.DL_ADDRESS,
    name: 'AAPL',
    symbol: 'AAPL',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0xC10b2Ce6A2BCfdFDC8100Ba1602C1689997299D3.svg'
  },
  {
    chainId: 56,
    address: AMZN.ADDRESS,
    dlAddress: AMZN.DL_ADDRESS,
    name: 'AMZN',
    symbol: 'AMZN',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x1085B90544ff5C421D528aAF79Cc65aFc920aC79.svg'
  },
  {
    chainId: 56,
    address: GOOGL.ADDRESS,
    dlAddress: GOOGL.DL_ADDRESS,
    name: 'GOOGL',
    symbol: 'GOOGL',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x9C169647471C1C6a72773CfFc50F6Ba285684803.svg'
  },
  {
    chainId: 56,
    address: COIN.ADDRESS,
    dlAddress: COIN.DL_ADDRESS,
    name: 'COIN',
    symbol: 'COIN',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0xB23DC438b40cDb8a625Dc4f249734811F7DA9f9E.svg'
  },
  {
    chainId: 56,
    address: BIDU.ADDRESS,
    dlAddress: BIDU.DL_ADDRESS,
    name: 'BIDU',
    symbol: 'BIDU',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x48D2854529183e1de3D36e29D437f8F6043AcE17.svg'
  },
  {
    chainId: 56,
    address: SPCE.ADDRESS,
    dlAddress: SPCE.DL_ADDRESS,
    name: 'SPCE',
    symbol: 'SPCE',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0x75bD0500548B49455D2Dfd86fa30Fba476Cb3895.svg'
  },
  {
    chainId: 56,
    address: SPY.ADDRESS,
    dlAddress: SPY.DL_ADDRESS,
    name: 'SPY',
    symbol: 'SPY',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/0xf2018b59F8f9BE020C12Cb0A2624200d9FBa2af1.svg'
  },
  {
    chainId: 56,
    address: XAU.ADDRESS,
    dlAddress: XAU.DL_ADDRESS,
    name: 'XAU',
    symbol: 'XAU',
    decimals: 18,
    logoURI:
      `https://raw.githubusercontent.com/chawanvtp/Dopple/main/assets/tokens/${XAU.ADDRESS}.svg`
  }
]
