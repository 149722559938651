import React from 'react'
import './index.css'
import { ConvertInput } from './components/ConvertInput'
import { ConvertOutput } from './components/ConvertOutput'
import { PercentageBar } from './components/PercentageBar'
import arrowDown from 'assets/images/arrow-down.png'
import { useState } from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import { TWX_Converter } from './convertToken'
import { useActiveWeb3React } from 'hooks'
import { useConvertContract, useTwinContract, useTwindexBalanceOf } from './hooks'
import { MaxUint256 } from '@ethersproject/constants'
import { useEffect } from 'react'
import { weiToFixed, weiToFixedDecimals } from 'utils/math-helpers'
import { useWeiAmountDecimals } from 'hooks/useWeiAmount'
import { BigNumber, bignumber } from 'mathjs'

export default function ConvertPage() {
  const [isDark] = useDarkModeManager()
  const [activeNumber, setActiveNumber] = useState<number>(0)
  const [allowance, setAllowance] = useState(bignumber(0))
  // const [userAllolwance, setUserAllolwance] = useState(null)
  const [input, setInput] = useState('')
  const { account } = useActiveWeb3React()
  const twinContract = useTwinContract()
  const convert_contract = useConvertContract()
  const { balanceTwin, balanceTwx, update } = useTwindexBalanceOf()
  const balanceTwinWei = useWeiAmountDecimals(balanceTwin, 0)
  const balanceTwxWei = useWeiAmountDecimals(balanceTwx, 0)
  const balanceTwinBN = bignumber(balanceTwinWei)
  // const balanceTwxBN = bignumber(balanceTwxWei)
  const inputFloat = useWeiAmountDecimals(parseFloat(input), 18)

  const useBalancePercent = (balance: BigNumber, activeNumber: number): { bn: BigNumber; num: number } => {
    const result = balance.mul(activeNumber).div(100)
    return {
      bn: result,
      num: parseFloat(weiToFixed(result,10))
    }
  }

  const { num: currentBalancePercentNum } = useBalancePercent(balanceTwinBN, activeNumber)

  // Set input twin after percent changed
  useEffect(() => {
    setInput(currentBalancePercentNum.toString())
  }, [currentBalancePercentNum])

  async function handleApproveTwinClicked() {
    if (!account) return
    try {
      const approveTransaction = await twinContract?.approve(TWX_Converter.ADDRESS, MaxUint256)
      await approveTransaction.wait()
      const allowanceTwinContract = await twinContract?.allowance(account, TWX_Converter.ADDRESS)
      setAllowance(bignumber(allowanceTwinContract.toString()))
    } catch (error) {
      setAllowance(bignumber(0))
    }
  }

  async function checkAllowance() {
    if (!account) return
    const result: BigNumber = await twinContract?.allowance(account, TWX_Converter.ADDRESS)
    const resultAsNumber = parseFloat(weiToFixed(result.toString(), 4))
    // return isZero(hex) ? setApproval(false) : setApproval(true)
    console.info('res => ', resultAsNumber)
    // allowance.lte(bignumber(0))
    if(result && resultAsNumber) setAllowance(bignumber(resultAsNumber))
  }
  useEffect(() => {
    checkAllowance()
  }, [])

  async function convertToken(amount: string) {
    // ----- GET dlToken(s) ALLOWANCE -----
    if (!account && allowance) return
    try {
      const data = await convert_contract?.convert(inputFloat)
      await data.wait()
      update()
    } catch (error) {
      console.log('error => ', error)
    }
  }

  return (
    <>
      <div className={`${isDark ? `dark` : `light`} convert-page w-100`}>
        <div className="container d-lg-flex justify-content-between mt-5">
          <div className="convert-container-left">
            <div className="convert-wrapper">
              <div>
                <div className="header-1 mb-4">Convert</div>
                <ConvertInput
                  input={input}
                  setInput={setInput}
                  balance={parseFloat(weiToFixedDecimals(balanceTwinWei, 18, 8))}
                />
              </div>
              <div className="mt-2 mb-4">
                <PercentageBar activeNumber={activeNumber} setActiveNumber={setActiveNumber} />
              </div>
              <div className="d-flex justify-content-center mb-4">
                <div className="arrow-down-wrapper">
                  <img src={arrowDown} alt={`arrow-down`} className="arrow-down" />
                </div>
              </div>
              <ConvertOutput output={input} balance={parseFloat(weiToFixedDecimals(balanceTwxWei, 18, 8))} />
              {allowance.lte(bignumber(0)) ? (
                <div className="mt-4">
                  <button className="convert-button mt-2" type="button" onClick={handleApproveTwinClicked}>
                    Approve
                  </button>
                </div>
              ) : (
                <div className="mt-4">
                  <button className="convert-button mt-2" type="button" onClick={() => convertToken(input)}>
                    Convert
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="convert-container-right">
            <div>
              <StepOneCard />
            </div>
            <div className="mt-3">
              <StepTwoCard />
            </div>
            <div className="mt-3">
              <StepThreeCard />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function StepOneCard() {
  return (
    <>
      <div className="convert-step-card">
        <div className="d-flex">
          <div className="d-flex justify-content-left" style={{ width: `20%` }}>
            <div className="number">1</div>
          </div>
          <div className="w-100">
            <div className="body">
              <h1>What is TWX?</h1>
              <div className="message">
                TWX is the upgraded version of TWIN token with several adjustments.
                <div
                  className="link ml-1 d-inline"
                  onClick={() =>
                    (window.location.href = `https://docs.dopple.finance/twindex/tokenomics/usdtwin-twindex-token`)
                  }
                >
                  Read more
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function StepTwoCard() {
  return (
    <>
      <div className="convert-step-card">
        <div className="d-flex">
          <div className="d-flex justify-content-left" style={{ width: `20%` }}>
            <div className="number">2</div>
          </div>
          <div className="w-100">
            <div className="body">
              <h1>Stake TWX to earn</h1>
              <div className="message ">
                TWX can also be used to provide liquidity and farm to earn more TWX, or stake TWX to earn TWX.
              </div>
              <button
                className="default-btn w-100 mt-4"
                type="button"
                onClick={() => (window.location.href = `#/farm`)}
              >
                Farm Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function StepThreeCard() {
  return (
    <>
      <div className="convert-step-card">
        <div className="d-flex">
          <div className="d-flex justify-content-left" style={{ width: `20%` }}>
            <div className="number">3</div>
          </div>
          <div className="w-100">
            <div className="body">
              <h1>Use TWX to mint tAssets</h1>
              <div className="message d-inline">
                TWX is partially required to mint tAssets, the synthetic assets on Twindex which could be held or farmed
                for profit.
              </div>
              <div
                className="link ml-1 d-inline"
                onClick={() =>
                  (window.location.href = `https://docs.dopple.finance/twindex/tokenomics/usdtwin-twindex-token`)
                }
              >
                Read more
              </div>
              <button className="default-btn disabled w-100 mt-4" type="button">
                Coming Soon
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
