import React from 'react'
import twinxTokenIcon from 'assets/images/staking/twx.svg'
import twinxTokenDarkIcon from 'assets/images/staking/dark-twx.svg'
import { OutputProp } from '../types'
import { useDarkModeManager } from 'state/user/hooks'

export const ConvertOutput = (props : OutputProp) => {
  const [isDark] = useDarkModeManager()
  return (
    <div className="input-wrapper d-flex">
      <div className="left w-100">
        <div className="row-1 d-flex">
          <div className="header-1 w-100">Output</div>
          <div className="balance w-100">Balance: {props.balance}</div>
        </div>
        <div className="row-2 d-flex">
          <div className="input-box w-100 d-flex">
            <img src={isDark ? twinxTokenDarkIcon : twinxTokenIcon} alt="twinTokenIcon" className="twin-token-icon" />
            <span className="header">TWX</span>
          </div>
          <input type="text" value={props.output} className="input-balance w-100 text-right" />
        </div>
      </div>
      <div className="right text-center">
        <div className="ratio">Ratio</div>
        <div className="value text-truncate">1 TWX</div>
      </div>
    </div>
  )
}
