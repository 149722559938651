/* eslint-disable @typescript-eslint/no-use-before-define */
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import APP_CONFIG from 'config'
import React, { useState } from 'react'
import { useDarkModeManager } from 'state/user/hooks'
// import Banner from '../../assets/images/landing-light-1.png'
// import DarkBanner from '../../assets/svg/dark-banner.svg'
// import Logo from '../../assets/svg/landing-logo.svg'
// import DoppleLogo from '../../assets/svg/twindex-dop-light.svg'
// import DoppleLogoDark from '../../assets/svg/twindex-dop-dark.svg'
import './style.css'
// import FooterLanding from 'components/Footer/Landing'
import arrowRightCircle from 'assets/svg/arrow-right-circle.svg'
import arrowRightCircleGreen from 'assets/svg/arrow-right-circle-green.svg'
import doppleDefiEcoLight from 'assets/svg/dopple-defi-eco.svg'
import doppleDefiEcoDark from 'assets/svg/dopple-defi-eco-dark.svg'
import chainLinkLogo from 'assets/svg/chainlink-logo.svg'
import binanceLogo from 'assets/svg/binance-logo.svg'
import { LANDING_SYNTHETIC_ASSETS, ORACLE_CONTRACTS } from 'pages/Info/constants'
import { filterSubGraph, getSubGraph } from 'hooks/subGraph'
import { useEffect } from 'react'
import { TApiData } from 'pages/Info/types'
import { SUB_GRAPH_DATA } from 'pages/Farm/types'
import { bignumber } from 'mathjs'
import { stockTokens } from 'pages/Mint/stockTokens'
import { StockTokenWithPrice } from 'pages/Mint/types'
import { weiToFixed } from 'utils/math-helpers'
import mkrMulticall from 'utils/mkrMulticall'
import PRICE_FEED_ABI from '../../constants/abis/SyntheticStock/priceFeed.json'
import { numberWithCommas, useActiveWeb3React } from 'hooks'
import { STAKING_LIST } from 'pages/Farm/constants'
// import { LANDING_SECTION11 } from './constants'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { STOCK_LP, SUB_GRAPH, TOKEN_LP } from 'pages/Farm/types'
import landingBanner01 from '../../assets/images/landing-light-1.png'
import landingBanner05 from '../../assets/images/landing-light-5.png'
import landingBanner06 from '../../assets/images/landing-light-6.png'
import landingBanner07 from '../../assets/images/landing-light-7.png'

import landingBannerDark01 from '../../assets/images/landing-dark-1.png'
import landingBannerDark05 from '../../assets/images/landing-dark-5.png'
import landingBannerDark06 from '../../assets/images/landing-dark-6.png'
import landingBannerDark07 from '../../assets/images/landing-dark-7.png'

const landingHeader1 = `THE FIRST`
const landingHeader2 = `SYNTHETIC ASSETS`
const landingPageBodyMessage = `Twindex enables trading of synthetic assets pegged
to real-world prices in the most accessible way with
its unique mechanism.`
const landingPageBodyMessage2 = ``

export interface apiV3Type {
  pool_id: string;
  name: string;
  asset_price: number;
  asset_address: string;
  lp_address: string;
  lp_value: number;
}


export default function Landing() {
  const [v3Data, setV3Data] = useState<Array<apiV3Type>>([])
  const { chainId } = useActiveWeb3React()
  const [isDark] = useDarkModeManager()
  const [isTrigger, setTrigger] = useState(false)
  const [apiData, setApiData] = useState<TApiData | null>(null)
  const [subGraphData, setSubGraphData] = useState<SUB_GRAPH_DATA | null>(null)
  const [oraclePriceList, setOraclePriceList] = useState<Array<StockTokenWithPrice>>()

  const getSubGraphData = () =>
    getSubGraph()
      .then(res => setSubGraphData(res as SUB_GRAPH_DATA))
      .catch(error => console.error('error: ', error))

  const calls = stockTokens.map(st => {
    return {
      address: '0xd4f061a6a276f8B0Ae83D210D838B45fCC7532B2',
      name: 'queryRate',
      params: [st.address, '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5']
    }
  })

  const fetchOraclePrice = () => {
    mkrMulticall(chainId, PRICE_FEED_ABI, calls).then(result => {
      const results: StockTokenWithPrice[] = []
      stockTokens.map((stock, index: number) => {
        results.push({
          ...stock,
          price: parseFloat(weiToFixed(bignumber(result[index].rate.toString()), 6))
        })
        return stock
      })
      setOraclePriceList(results)
      return results
    })
  }

  const fetchData = async () => {
    await fetch('https://api-v3.twindex.com/twindexlp')
    .then(response => response.json())
    .then(data => {
      if (v3Data.length == 0) setV3Data(data)
    })

    await fetch('https://api-v2.twindex.com/')
      .then(response => response.json())
      .then(data => {
        if (!apiData) setApiData(data)
      })
  }

  useEffect(() => {
    fetchData()
    getSubGraphData()
    fetchOraclePrice()
    // Interval loop
    // setInterval(fetchData, 60000)
  }, [])
  return (
    <div className={`landing-page ${isDark ? `dark` : `light`}`}>
      {/* Section - 01 */}
      <div className={`container landing-wrapper-01`}>
        <div className="row">
          <img
            src={isDark ? landingBannerDark01 : landingBanner01}
            alt={`landingBanner01`}
            className={`col-12 d-lg-none pb-5 mt-5 pr-0 pl-0 w-100`}
          />
          <div className={`col-12 col-lg-6 px-5 pt-lg-5 ${isDark ? `text-white` : `text-black`}`}>
            <div className="header-wrapper">
              <h1 className={`header-1 mb-2`}>{landingHeader1}</h1>
              <h1 className="header-2">{landingHeader2}</h1>

              <div className="mb-3 mt-3" style={{ color: `#9ca3af`, marginLeft: '0.15rem' }}>
                On the Binance Smart Chain
              </div>

              <p style={{ fontSize: `1rem` }} className="mt-3">
                {landingPageBodyMessage}
              </p>
              <p style={{ fontSize: `1rem` }} className="mt-3">
                {landingPageBodyMessage2}
              </p>
              <button
                type="button"
                className="launch-app-button mt-2"
                onClick={() => (APP_CONFIG.MAINTAINANCE ? setTrigger(!isTrigger) : window.location.href = `#/swap`)}
              >
                <>
                  Launch App
                  <img src={arrowRightCircle} alt="arrow-right-circle" className="ml-2" />
                </>
              </button>
            </div>
            <div className="part-of mt-5">PART OF</div>
            <img src={isDark ? doppleDefiEcoDark : doppleDefiEcoLight} alt="doppleDefiEcoLight" className="mb-4" />
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block ">
            <img
              src={isDark ? landingBannerDark01 : landingBanner01}
              alt={`landingBanner01`}
              className={`w-100`}
              style={{ position: `absolute`, bottom: `0` }}
            />
          </div>
        </div>
      </div>

      {/* Section - 02 */}
      <div
        className={`container min-vw-100 landing-wrapper-02 mb-lg-5`}
        style={{ backgroundColor: isDark ? `#192230` : `#f9fafb` }}
      >
        <div className="row">
          <div className="col-12 header-wrapper">
            <h1>Powered by</h1>
          </div>
          <div className="col-12 col-lg-6 pr-xl-5 pb-0 img-wrapper">
            <img src={chainLinkLogo} alt="chainLinkLogo" className={`float-lg-right`} />
          </div>
          <div className="col-12 col-lg-6 pl-xl-5 img-wrapper">
            <img src={binanceLogo} alt="binanceLogo" className={`float-lg-left`} />
          </div>
        </div>
      </div>

      {/* Section - 03 */}
      <div className={`container landing-wrapper-03`}>
        <div className="row mb-5 mt-5">
          <div className="col-12 mb-4 mt-5 text-center">
            <h1>Why Synthetic Assets Trading?</h1>
          </div>
        </div>
        <div className="row">
          {LANDING_SYNTHETIC_ASSETS.map(content => (
            <div className="col-12 col-xl-4" key={`landing-03-${content.header}`}>
              <div className="card">
                <img src={content.icon} alt={`${content.icon}`} className="icon" />
                <div className="header">{content.header}</div>
                <div className="body">{content.body}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="row text-center">
          <div className="col-12">
            <button
              type="button"
              className="launch-app-button m-0 mt-2"
              onClick={() => (APP_CONFIG.MAINTAINANCE ? setTrigger(!isTrigger) : window.location.href = `#/swap`)}
            >
              <>
                Launch App
                <img src={arrowRightCircle} alt="arrow-right-circle" className="ml-2" />
              </>
            </button>
          </div>
        </div>
      </div>

      {/* Section - 04 */}
      <div className={`container w-100 landing-wrapper-04`}>
        <div className="row mb-lg-5 mt-5">
          <div className="col-12 mt-5 text-center">
            <h1>Our Features</h1>
          </div>
          <div className="col-12 mb-4 mt-2 text-center">
            <div className="body-wrapper">
              Twindex provides multiple ways to use Twindex and make profits, even when the cryptocurrency market is in
              downfall. Benefit from tokenomics.
            </div>
          </div>
        </div>
      </div>

      {/* Section - 05 */}
      <div className={`container w-100 mb-4 landing-wrapper-05`}>
        <div className="row">
          <div className="col-0 col-lg-6"></div>
          <img
            src={isDark ? landingBannerDark05 : landingBanner05}
            alt={`landingBanner05`}
            className={`col-12 d-lg-none pb-5 pl-5 pr-5`}
          />
          <div className={`col-12 col-lg-6 px-5 pt-lg-5 ${isDark ? `text-white` : `text-black`}`}>
            <div className="header-wrapper">
              <h1 className={`header-1`}>Trade</h1>
              <p style={{ fontSize: `1rem` }} className="mt-3">
                Be day traders or value investors just like your familiar asset exchanges. Immediately trade 24/7 and
                make profits with your familiar trading view interface
              </p>
              <button
                type="button"
                className="launch-app-button mt-2  "
                onClick={() =>
                  (window.location.href = `#/swap?outputCurrency=0x3806aae953a3a873D02595f76C7698a57d4C7A57`)
                }
              >
                <>
                  Trade Now
                  <img src={arrowRightCircle} alt="arrow-right-circle" className="ml-2" />
                </>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Section - 06 */}
      <div className={`container w-100 mb-4 landing-wrapper-06`}>
        <div className="row">
          <img
            src={isDark ? landingBannerDark06 : landingBanner06}
            alt={`landingBanner06`}
            className={`col-12 d-lg-none p-5`}
          />
          <div className={`col-12 col-lg-6 px-5 pt-lg-5 ${isDark ? `text-white` : `text-black`}`}>
            <div className="header-wrapper">
              <h1 className={`header-1`}>Farm</h1>
              <div style={{ fontSize: `1rem` }} className="mt-3 mb-2">
                Earn profits just by holding your synthetic assets. Provide liquidity with equal amount of synthetic
                assets and DOLLY stablecoin tokens and farm to earn TWIN token as reward
              </div>
              <button
                type="button"
                className="launch-app-button mt-2  "
                onClick={() => (window.location.href = `#/farm`)}
              >
                <>
                  Farm Now
                  <img src={arrowRightCircle} alt="arrow-right-circle" className="ml-2" />
                </>
              </button>
            </div>
          </div>
          <div className="col-0 col-lg-6"></div>
        </div>
      </div>

      {/* Section - 07 */}
      <div className={`container w-100 mb-4 landing-wrapper-07`}>
        <div className="row">
          <div className="col-0 col-lg-6"></div>
          <img
            src={isDark ? landingBannerDark07 : landingBanner07}
            alt={`landingBanner07`}
            className={`col-12 d-lg-none p-5`}
          />
          <div className={`col-12 col-lg-6 px-5 pt-lg-5 ${isDark ? `text-white` : `text-black`}`}>
            <div className="header-wrapper">
              <h1 className={`header-1`}>Mint</h1>
              <div style={{ fontSize: `1rem` }} className="mt-3 mb-2">
                For advanced users, earn profits by keeping the synthetic assets price close to real-world market price.
                Mint and redeem tokens and earn when the price falls.{' '}
                <a href="https://docs.dopple.finance/" style={{ color: `#19c6bc` }}>
                  Learn more
                </a>
              </div>
              <button
                type="button"
                className="launch-app-button mt-2  "
                onClick={() => (window.location.href = `#/mint`)}
              >
                <>
                  Mint Now
                  <img src={arrowRightCircle} alt="arrow-right-circle" className="ml-2" />
                </>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Section - 08 */}
      <div className={`container w-100 landing-wrapper-08 text-center`}>
        <h1 className="pt-5 pb-4 pt-lg-0">Popular Assets to Trade</h1>
        <div className="table-wrapper">
          <table className="w-100">
            <thead>
              <tr className="header-row">
                {[`Assets`, `Twindex Price`, `Oracle Price`, `Difference`, `Premium/Discount`, `LPs Value`].map((header, index) => (
                  <th
                    key={`landing-08-${header}`}
                    className={`${index <= 2 ? `` : ``} ${
                      header === 'Assets' ? `text-center asset-header` : `text-left`
                    }`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {subGraphData
                ? oraclePriceList?.map((price, index) => {
                    const filteredData = filterSubGraph(price.name, subGraphData)
                    const pairPrice =
                      filteredData.tokenPrice1 > filteredData.tokenPrice0
                        ? filteredData.tokenPrice1
                        : filteredData.tokenPrice0
                    return (
                      <tr className={`body-row ${!index ? `active` : ``}`} key={`landding-08-${index}`}>
                        <td className="name text-left">
                          <img
                            className="logo ml-3 ml-lg-0 "
                            src={
                              isDark
                                ? ORACLE_CONTRACTS.find(ele => ele.address === price.address)?.image_url_dark
                                : ORACLE_CONTRACTS.find(ele => ele.address === price.address)?.image_url
                            }
                            alt={ORACLE_CONTRACTS.find(ele => ele.address === price.address)?.image_url}
                          />
                          <div className="d-flex justify-content-center ml-0 ml-lg-3 d-lg-inline mt-2 mt-lg-0">
                            {`d` + price.name}
                          </div>
                        </td>
                        <td className="text-left twindex-price">
                          {`$${numberWithCommas(parseFloat(pairPrice.toString()).toFixed(2))}`}
                        </td>
                        <td className="text-left oracle-price">{`$${numberWithCommas(price.price.toFixed(2))}`}</td>
                        <td
                          className={`text-left ${
                            parseFloat(pairPrice.toString()) - parseFloat(price.price.toString()) > 0 ? `green` : `red`
                          }`}
                        >
                          {(parseFloat(pairPrice.toString()) - parseFloat(price.price.toString())).toFixed(2)}
                        </td>
                        <td
                          className={`text-left ${
                            parseFloat(pairPrice.toString()) - parseFloat(price.price.toString()) > 0 ? `green` : `red`
                          }`}
                        >
                          {parseFloat(pairPrice.toString()) - parseFloat(price.price.toString()) > 0 ? (
                            <FontAwesomeIcon icon={faArrowUp} style={{ marginRight: `0.5rem`, fontSize: `0.85rem` }} />
                          ) : (
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              style={{ marginRight: `0.5rem`, fontSize: `0.85rem` }}
                            />
                          )}
                          {(
                            (100 * Math.abs(parseFloat(pairPrice.toString()) - parseFloat(price.price.toString()))) /
                            (price.price as number)
                          ).toFixed(2) + `%`}
                        </td>
                        <td className="text-left twindex-price">
                      ${numberWithCommas(v3Data.find(ele => ele.asset_address == price.address)?.lp_value.toFixed(2))}
                      {/* {`$${numberWithCommas(parseFloat("999").toFixed(2))}`} */}
                    </td>
                      </tr>
                    )
                  })
                : ``}
            </tbody>
          </table>
        </div>
      </div>


      {/* Section - 09 */}
      <div className={`landing-wrapper-09 text-center`} style={{ maxWidth: `100%` }}>
        <div className="container pb-5 pt-5">
          <h1 className="mb-5">Popular Assets to Farm</h1>
          <div className="row">
            {STAKING_LIST.map((element, index) =>
              index < 8 || index > 11 ? (
                ``
              ) : (
                <div className="col-12 col-md-6" key={`landing-09-${index}`}>
                  <div className="farm-card">
                    <img src={element.icon} alt={element.address} />
                    <h2 className="mt-4">{element.name}</h2>
                    {/* <div className="row mt-5">
                      <div className="col-12">
                        <div className="float-left header-2">APR</div>
                        <div className="float-right body-2">
                          {apiData && numberWithCommas(apiData.lp_data[element.lp_address as any]?.apr.toFixed(2)) + `%`}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="float-left header-2">TVL</div>
                        <div className="float-right body-2">
                          ${apiData && numberWithCommas(apiData.lp_data[element.lp_address as any]?.tvl.toFixed(2))}
                        </div>
                      </div>
                    </div> */}
                    <div className="row mt-4">
                      <div className="col-12">
                        <button className="farm-btn" type="button" onClick={() => (window.location.href = '#/farm')}>
                          Farm Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <button
            type="button"
            className="launch-app-button mt-2 m-0"
            onClick={() => (window.location.href = `#/farm`)}
          >
            <>
              Go to Farm
              <img src={arrowRightCircle} alt="arrow-right-circle" className="ml-2" />
            </>
          </button>
        </div>
      </div>

      {/* Section - 10 */}
      <div className={`container landing-wrapper-10 text-center`}>
        <h1 className="mb-4 header">Want to Learn More?</h1>
        <div className="body mb-4">
          You can explore our documentation and join our communities to get to know the amazingness of Twindex.
        </div>
        <a href="#/farm" className="link-wrapper">
          Join the community
          <img
            src={arrowRightCircleGreen}
            alt="arrow-right-circle"
            className="ml-2"
            style={{ stroke: `#19c6bc` }}
          />{' '}
        </a>
      </div>

      {/* Section - 11 */}
      {/* <div className={`landing-wrapper-11 d-none d-md-block`} style={{ maxWidth: `100%` }}>
        <div className="container">
          <div className="row">
            <div className="col-9">
              <div className="row">
                {LANDING_SECTION11.header.map(header => (
                  <div className={`col-3 header mb-2`} key={`LANDING_SECTION11-1-${header}`}>
                    {header}
                  </div>
                ))}
                {LANDING_SECTION11.menu.map((menu, index) => (
                  <div className={`col-3 body mb-2`} key={`LANDING_SECTION11-2-${index}`}>
                    {menu.text}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-3">
              <div className="header">For more information or help</div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <FooterLanding /> */}
    </div>
  )
}
